import React from 'react';

import {Dragger} from 'antd/lib/upload';
import Icon from 'antd/lib/icon';
import { Typography } from '@material-ui/core'

const UploadDragger = ({updateText, saveFile, loadPanel, closeTabs, classes}) => (

    <Dragger
      accept=".txt, .csv, .xlsx"
      multiple={false}
      showUploadList={false}
      name="file"
      id="ant-upload"
      beforeUpload={(file, fileList) => {
        if (file){
          const reader = new FileReader();
                const fileExtension = file.name.substr(file.name.length - 3)
                if (['txt', 'csv', "lsx"].indexOf(fileExtension) === -1){
                  alert("File format not suppported")
                  return false
                }

                if (fileExtension === "txt"){
                  reader.readAsText(file);

                  reader.onload = event => {
                    updateText(event.target.result);
                  }
                } else {
                  reader.readAsBinaryString(file)
                  reader.onloadend = ()  => {
                    saveFile(file, reader.result);
                    closeTabs();
                  }
                }
          return false;
        }
      }}
    >
      <p className="ant-upload-drag-icon">
	     <Icon type="upload" />
      </p>

      <Typography component="span" align="center" variant="subtitle1">
        Click or drag file to upload
      </Typography>
      <Typography component="span" align="center" variant="subtitle2">
        (.xlsx, .csv or .txt)
      </Typography>
    </Dragger>

)

export default UploadDragger;
