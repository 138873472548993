import React from 'react'

const PropDepExample = (props) => {
    const EXAMPLE_TEXT_A = `You want to measure awareness of your brand \
    before and after a marketing campaign. You set up a survey with ${props.n} \
    potential customers before the campaign and ask them about their awareness \
    of your brand. The resulting awareness is ${props.prop_a}%. \
    After the campaign, you survey the same group for brand awareness again. \
    The post-campaign awareness is ${props.prop_b}%. Your post-campaign brand \
    awareness is significantly different with ${props.sig}% certainty.`
    const EXAMPLE_TEXT_B = `You are doing a survey with 10 items and you want \
    to determine if item number 4 and item number 6 are rated significantly \
    differently.`
    return (
      <div id="prop-example">
        <p className="sig-small-title">Example 1</p>
        <p className="sig-medium-text">{ EXAMPLE_TEXT_A }</p>
        <p className="sig-small-title">Example 2</p>
        <p className="sig-medium-text">{ EXAMPLE_TEXT_B }</p>
      </div>
    )
}

const PropIndExample = (props) => {

      const EXAMPLE_TEXT_A = `
      Imagine you want to test the acceptance of an advertising message
      in a survey. You want to compare the proportion from two independent
      groups (i.e. groups with no overlap between them such as men and women)
      to determine if their ratings are significantly different from one another.
      `
      const EXAMPLE_TEXT_B = `
      The resulting proportions for the two groups for the entered sample sizes
      are ${props.prop_a}% and ${props.prop_b}%. With ${props.sig}%
      certainty you can say that the observed porportion for acceptance of the
      message for Group A is significantly different from Group B.
      `

      return (
        <div id="prop-example">
          <p className="sig-small-title">Example</p>
          <p className="sig-medium-text">{ EXAMPLE_TEXT_A }</p>
          <p className="sig-medium-text">{ EXAMPLE_TEXT_B }</p>
        </div>
      )
}

const ConfExample  = (props) => {

    const READING_EXAMPLE = `
    Imagine you are measuring customer satisfaction and your observed
    proportion of satisfied customers is ${props.percentage}%.
    With ${props.sig}% certainty you can say that in the real world
    the confidence interval of
    ${(props.percentage + props.ci_value).toFixed(1)}% to
    ${(props.percentage - props.ci_value).toFixed(1)}% will contain the
    true population proportion.
    `
    const APPLICATION_EXAMPLE = `
    Question: You want to have a small confidence interval of +/-2%.
    How large would your sample size have to be at a given confidence level?
    `
    const APPLICATION_HOW_TO = `
    const howto_text = "How to do it: Step 1: Set the confidence level using
    the slider, let's say 95%. Step 2: To be on the safe side, use a
    proportion of about 50% as the interval will be largest around this
    proportion. Step 3: Use the slider for sample size and move it along.
    The different confidence intervals and corresponding ranges will be
    displayed."
    `

    return (
      <div id="conf-example">
        <div className="sig-small-title">EXAMPLES</div>
        <br />
        <div className="sig-label">Reading example</div>
        <div className="sig-medium-text">{ READING_EXAMPLE }</div>
        <br />
        <div className="sig-label">Application Example</div>
        <div className="sig-medium-text">{ APPLICATION_EXAMPLE }</div>
        <div className="sig-medium-text">{ APPLICATION_HOW_TO }</div>
      </div>
    )
}

export { PropDepExample, PropIndExample, ConfExample };
