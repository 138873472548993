import React, { Component } from "react";

import shape from "../assets/img/shape.svg";
import shuffle from "../assets/img/cross-over-shuffle.svg";
import download from "../assets/img/download-3.svg";

import { Grid } from "@material-ui/core";
import MediaQuery from "react-responsive";

const TypeSection = props => (
  <>
    <img src={shape} alt="Shape" className="wc-icon-cloud ml-3" />

    <div className="btn-group ml-3" role="group">
      <button
        type="button"
        className="btn btn-sm btn-light"
        onClick={props.setCloud}
      >
        Cloud
      </button>

      <button
        type="button"
        className="btn btn-sm btn-light"
        style={{ marginLeft: "-5px" }}
        onClick={props.setLinear}
      >
        Linear
      </button>
    </div>
  </>
);

const RedrawSection = props => (
  <>
    <img src={shuffle} alt="Shuffle/Reset" className="ml-3" />
    <button
      type="button"
      className="btn btn-sm btn-light ml-3"
      style={{ marginLeft: "-5px" }}
      disabled={props.isLinear}
      onClick={props.shuffle}
    >
      Shuffle
    </button>
    <button
      type="button"
      className="btn btn-sm btn-light"
      style={{ marginLeft: "-5px" }}
      onClick={props.random}
    >
      Random
    </button>
    <button
      type="button"
      className="btn btn-sm btn-light"
      style={{ marginLeft: "-5px" }}
      onClick={props.reset}
    >
      Reset
    </button>
  </>
);

const DownloadSection = props => (
  <>
    <Grid item>
      <img src={download} alt="Download" className="ml-3" />
    </Grid>
    <Grid item>
      <div className="btn-group col p-0 ml-3" role="group">
        <button
          type="button"
          className="btn btn-sm btn-light"
          onClick={() => props.exportSVG()}
        >
          SVG
        </button>
        <button
          type="button"
          className="btn btn-sm btn-light"
          style={{ marginLeft: "-5px" }}
          onClick={() => props.exportPNG()}
        >
          PNG
        </button>
      </div>
    </Grid>
  </>
);

class ControlPanel extends Component {
  constructor(props) {
    super(props);
    this.isLinear = this.isLinear.bind(this);
  }

  isLinear() {
    return this.props.cloudType === "linear";
  }

  render() {
    if (this.props.hide === true) {
      return <span />;
    }

    return (
      <>
        <MediaQuery minWidth={700}>
          <ControlPanelGrid
            layout="row"
            isLinear={this.isLinear}
            {...this.props}
          />
        </MediaQuery>

        <MediaQuery maxWidth={699}>
          <ControlPanelGrid
            layout="column"
            isLinear={this.isLinear}
            {...this.props}
          />
        </MediaQuery>
      </>
    );
  }
}

const ControlPanelGrid = props => ({
  render() {
    let className = "";
    let mr = "";
    let pr = "pr-3";
    if (props.layout === "column") {
      className = "mb-2";
      mr = "mr-3";
      pr = "";
    }

    return (
      <Grid
        item
        container
        className={`wc-control-panel mt-3 ${pr}`}
        direction={props.layout}
        alignItems="flex-end"
        justify="flex-end"
      >
        <Grid item className={className}>
          <TypeSection setLinear={props.setLinear} setCloud={props.setCloud} />
        </Grid>
        <Grid item className={className}>
          <RedrawSection
            isLinear={props.isLinear()}
            shuffle={props.shuffle}
            reset={props.reset}
            random={props.random}
          />
        </Grid>
        <Grid item container className={`auto-width ${mr}`}>
          <DownloadSection
            exportPNG={props.exportPNG}
            exportSVG={props.exportSVG}
          />
        </Grid>
      </Grid>
    );
  }
});

export default ControlPanel;
