import React, { Component } from "react";
import Favicon from "react-favicon";

import Footer from "./Footer";
import MenuBar from "./Header";
import LoginBox from "./Login";

import favicon from "../assets/img/favicon.ico";

import {
  Grid,
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline
} from "@material-ui/core";

class Container extends Component {
  render() {
    this.props.gaPageview();
    const {
      app,
      group,
      auth: { isAuthenticated }
    } = this.props;
    const isMobile = window.innerWidth <= 950; // 850

    return (
      <MuiThemeProvider theme={theme}>
        <Favicon url={favicon} />
        <CssBaseline />
        <Grid
          container
          direction="column"
          className="full-height max-width-all flex-1 mx-auto"
        >
          {(isAuthenticated() || this.props.public) && (
            <>
              <MenuBar
                auth={this.props.auth}
                path={this.props.location.pathname}
                name={this.props.name}
                app={app === undefined ? group : app}
              />
              <this.props.main
                isMobile={isMobile}
                {...this.props}
                auth={this.props.auth}
              />
              <Footer />
            </>
          )}
          {!isAuthenticated() && !this.props.public && (
            <LoginBox login={this.props.auth.login} />
          )}
        </Grid>
      </MuiThemeProvider>
    );
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      colorSecondary: {
        color: "#0f23a4 !important"
      }
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: ["Factworks"],
    h2: {
      // tool title
      marginTop: "20px",
      marginBottom: "10px",
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "700"
    },
    subtitle1: {
      // tool description
      fontSize: "22px",
      lineHeight: "32px"
    },
    h3: {
      // home card title
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "700"
    },
    subtitle2: {
      // home card description
      fontSize: "16px",
      lineHeight: "22px"
    },
    body1: {
      // stats calculator
      lineHeight: "28px",
      fontSize: "18px"
    }
  },
  palette: {
    text: {
      primary: "#000028",
      secondary: "#fff"
    },
    primary: {
      main: "#00abff"
    },
    secondary: {
      main: "#000028"
    },
    background: {
      default: "#0f23a4"
    }
  },
  shadows: Array(25).fill(0)
});
export default Container;
