import React from "react";
import history from "../../lib/history";
import { Button } from "react-bootstrap";
import { Grid, Typography } from "@material-ui/core";

const CalculatorMenu = ({ currentApp }) => {
  return (
    <Grid
      container
      direction="row"
      xs={12}
      className={"mx-auto pb-3 w-100"}
      style={{ maxWidth: "1200px" }}
      spacing={16}
    >
      <Grid item xs={12} md={4}>
        <Button
          bsStyle={currentApp === "conf" ? "active" : "inactive"}
          bsSize="medium"
          block
          onClick={() => history.replace("/calculator/conf")}
        >
          <Typography color="textSecondary" noWrap="false">
            Confidence Interval{" "}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button
          bsStyle={currentApp === "dep" ? "active" : "inactive"}
          bsSize="medium"
          block
          onClick={() => history.replace("/calculator/dep")}
        >
          <Typography color="textSecondary">
            Proportion Test - Dependent
          </Typography>
        </Button>
      </Grid>

      <Grid item xs={12} md={4}>
        <Button
          bsStyle={currentApp === "ind" ? "active" : "inactive"}
          bsSize="medium"
          block
          onClick={() => history.replace("/calculator/ind")}
        >
          <Typography color="textSecondary">
            Proportion Test - Independent
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default CalculatorMenu;
