import React from 'react'

import { Tooltip } from 'react-tippy'
import Slider from 'rc-slider'
import {Typography} from '@material-ui/core'

import 'react-tippy/dist/tippy.css'

class RSlider extends React.Component {

  /* Defines a generic  object */

  constructor(props){

    super(props);
    this.changed = this.changed.bind(this);

    this.state = {
        value: this.props.value,
        gvalue:this.props.value,
        range: this.props.max - this.props.min,
        isiOS: (navigator.platform === 'iPhone' || navigator.platform === 'iPad')

    }

    this.updateTextField = true;

    this.inputChange = this.inputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.resetLabel = this.resetLabel.bind(this);
  }

  changed(value) {
    if(this.updateTextField) {
      this.setState({
        'value': value,
        'gvalue':value,
      })
      // Pass new input_value to parent component
      this.props.handler(+value)
    } else {
      this.updateTextField = true;
    }
  }

  onKeyUp(e) {
      var value = e.target.value;

      if(value === ",") {
          value = "";
          e.target.value = value;
      }
      if(value === "..") {
          value = "."
          e.target.value = value;
      }
  }

  onKeyDown(e) {

     const isMobile = window.innerWidth <= 950

     var legal_keys = ['0','1','2','3','4','5','6','7','8','9','.' ]
     var legal_key = false;
     for(var i = 0; i <= 10; i++)
        if(legal_keys[i] === e.key) {
            legal_key = true;
            break;
        }
     var fake;
     if (e.keyCode === 8 || e.keyCode === 46) {

         if (String(this.state.gvalue).length === 1) {
              this.setState({gvalue:""})
              e.target.value = ""
              if(isMobile && this.state.isiOS) {
                  fake = document.getElementById("fake-"+this.props.id);
                  fake.value = "";
              }
          } else if(String(this.state.gvalue).length === 2 && String(this.state.gvalue).charAt(0) === '.') {
              this.setState({gvalue:""})
              e.target.value = ""
              if(isMobile  && this.state.isiOS) {
                fake = document.getElementById("fake-"+this.props.id);
                fake.value = "";
              }
          }

    } else if(e.keyCode === 37 || e.keyCode === 39 ) {
      // pass
    } else if(!legal_key) {
      e.preventDefault();
    } else if((e.key === '.' || e.key === ',') && this.props.decimals === false) {
      e.preventDefault();
    }

    if(e.keyCode === 190  && (String(this.state.value).indexOf('.') >= 0 || e.target.value === null || e.target.value === '') ) {
       e.preventDefault();
    }
  }

  onBlur(e) {
     var input_value = e.target.value;
     if (input_value === '' || input_value === null || (input_value < this.props.min)){
         if(this.props.id === "perc_slider")
            input_value = "20.5";
         else
            input_value = String(this.props.min)
     }
     this.setState({
       'value': input_value,
       'gvalue':input_value,
       'controlledPosition': {x: ((this.state.width*(input_value-this.props.min))/this.state.range), y:0}
     })
     // Update parent component

     this.props.handler(+input_value);
 }



  inputChange(e){
    /* Updates state when user inputs text */
    var input_value;
    var x_value;

    // Clean up text input, prevent non-numeric chars

    input_value = e.target.value.replace('%', '');
    input_value = input_value.replace('e', '');
    input_value = input_value.replace(',', '.');

    if (input_value === '' || input_value === null){
            return true;
    }
    if (isNaN(input_value) === true){
      return true;
    }

    if (this.props.decimals === false && input_value.toString().indexOf('.') > -1){
      return true;
    }

    // Limit decimals to two decimal spaces, if allowed

    if (this.props.decimals !== false && input_value !== ''){
      input_value = input_value.toString().match(/^-?\d*(?:\.\d{0,2})?/)[0]

    }

    // Replace disallowed values

    if (this.props.sig === true && input_value >= 100){
      input_value = '99';
    }


    if (this.props.percentage === true && input_value > 100){
      input_value = '100';
    }

    if (this.props.percentage === true && input_value < 0) {
      input_value = '0';
    }

    // Limit cursor position to max value

    if (input_value > this.props.max){
      x_value = this.props.max;
    } else {
      x_value = input_value;
    }

    if (input_value < this.props.min){
      x_value = this.props.min;
    }

    // Update state

    this.setState({
      'value': input_value,
      'gvalue':input_value,
      'controlledPosition': {x: ((this.state.width*(x_value-this.props.min))/this.state.range), y:0}
    })

    // Update parent component

    let condition = (input_value !== '' && input_value !== null && input_value > this.props.min);

    // soft indicates that the slider is bound my max value,
    // but the text input isn't
    if(this.props.soft !== true) {
      condition = condition && (input_value < this.props.max)
    } else if (input_value > this.props.max){
      // keeps the update to the slider caused by the handler call
      // from updating the text field, when they should be able to diverge
      this.updateTextField = false;
    }

    if (condition){
        this.props.handler(+input_value);
    }
  }

  resetLabel(){
    const isMobile = window.innerWidth <= 950
    this.setState({
      'label': isMobile ? this.props.label_mobile : this.props.label
    })
  }

  componentDidMount() {
    this.resetLabel()
    window.addEventListener('resize', this.resetLabel)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetLabel)
  }

  render(){

    const isMobile = window.innerWidth <= 950

    /* Render the slider */

    var label_colour;

    // Set label colour

    if (this.props.label_colour){
      label_colour = this.props.label_colour;
    } else {
      label_colour = this.props.colour;
    }

    var input_value = this.state.value;

    // Display some usage info and/or tip

    if (this.props.tip === true){
        if (this.state.value < this.props.max){
            // info_text = <p>Enter value or slide</p>
        } else {
            // info_text = <p>You can enter a larger N value</p>
        }
    }

    var tooltip;
    if (this.props.tooltip){
        tooltip = <Tooltip
                    title={this.props.tooltip}
                    position="bottom"
                    trigger={isMobile ? "click" : "mouseenter"}
                    arrow={true}
                    size="big"
                    style={{cursor: "pointer"}}
                    >
                      <span style={{fontSize: "0.8em", fontFamily: "Factworks"}}>
                          &nbsp;<sup>[?]</sup>
                      </span>
                    </Tooltip>
    }

    // Mark label offset
    let m = isMobile ? 3: 1

    var sig_marks = {
      80:'',
      85:'',
      90: {'style': {'left': (((90-this.props.min)/this.state.range*100) - m).toString() + '%'}, 'label': '90%'},
      95: {'style': {'left': (((95-this.props.min)/this.state.range*100) - m).toString() + '%'}, 'label': '95%'},
      99: {'style': {'left': (((99-this.props.min)/this.state.range*100) - m).toString() + '%'}, 'label': '99%'},
    }

    let track_color;
    switch(this.props.colour){
      case "blue":
        track_color = "#0f41d7"
        break;
      case "cyan":
        track_color = "#00abff"
        break
      case "grey":
        track_color = "#465a64";
        break;
      default:
        track_color = "#afbec5"
        break;
    }

    // set grid break points based on sizes
    let sliderWidth
    let labelOffset
    let inputOffset

    switch(this.props.size){
      case 6:
        sliderWidth = isMobile ? 80: 70;
        labelOffset = isMobile ? 30 : 38 //27, 35;
        inputOffset = isMobile ? 20 : 30
        break;
      case 12:
        sliderWidth = 80;
        labelOffset = isMobile ? 27 : 25 // 22;
        inputOffset = 20
        break;
      default:
        // pass
    }

    const LABEL_WIDTH = 100 - sliderWidth

    return (

      /* Displays the slider */

      <div id={this.props.id}>

        <div className="sig-slider" style={{"width": sliderWidth.toString() + '%'}}>

          <div
            className="slider-label-container mt-3"
            style={{
              "right": labelOffset.toString() + '%',
              "width": LABEL_WIDTH.toString() + '%'}}>
            <span className="slider-label">
              <Typography color="textPrimary" noWrap={true} >{ this.state.label }{ tooltip }</Typography>
            </span>
          </div>

          <div style={{"right": inputOffset.toString() + '%', 'position': 'relative'}}>

              <input
                type={this.state.isiOS ? "number" : "text"}
                id={(this.state.isiOS ? "real-" : '') + this.props.id}
                className={"slider_value " + label_colour}
                value={this.state.gvalue}
                onChange={this.inputChange}
                onKeyDown={this.onKeyDown}
                onKeyUp={this.onKeyUp}
                onBlur={this.onBlur}
                inputMode="numeric"
                style={{backgroundColor:'transparent'}}
              />

              {this.state.isiOS ?
                <input type="text"
                 id={"fake-" + this.props.id}
                 className={"slider_value " + label_colour}
                 value={this.state.gvalue}
                 />:""
               }

              {this.props.percentage ?
                  <span
                    className={"slider_value " + label_colour}
                    id={this.props.id + "_percentage"}
                    style={{marginLeft:-115+String(input_value).length * 16 + (String(input_value).indexOf('.') === -1 ? 0 : -6)}}>%
                  </span>:<span className={"slider_value " + label_colour} />
              }
          </div>

          <div>
              <Slider
                min={Number(this.props.min)}
                max={Number(this.props.max)}
                step={this.props.percentage && !this.props.sig ? 0.1 : 1}
                value={Number(this.state.gvalue)}
                onChange={this.changed}
                marks = {this.props.sig ? sig_marks : {}}
                trackStyle={{ backgroundColor: track_color, color: track_color,  top:'4px', height: 6}}
                handleStyle={{
                  borderColor: track_color,
                  height: 28,
                  width: 28,
                  marginLeft: -14,
                  marginTop: -14,
                  backgroundColor: track_color
                }}
                dotStyle={{ backgroundColor: track_color, borderColor: track_color }}
                activeDotStyle={{ backgroundColor: '#fff', borderColor: track_color }}
              />
          </div>

        </div>
      </div>

    )
  }
}

export default RSlider
