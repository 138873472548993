const defaultText = `Factworks is an international market research and analytics company with offices in Berlin and San Francisco and an academic connection to the Business School at the University of Geneva. We combine state-of-the-art market research methods and industry expertise with years of consulting experience to help our clients make successful marketing and management decisions.
The steady growth of our business is almost exclusively the result of satisfied clients who continue to expand their partnerships with us and refer us to others.
Factworks was founded in 2002 as a spin-off of the Society and Technology Research Group, a think tank of Daimler AG. A classic start-up, the company began with three coworkers in the living room of one of our founders. Today Factworks is a team of 45 and manages large-scale international projects.
We combine the best qualities of a think tank and a start-up, merging our passion for market research with strategic thinking, scientific precision, speed, and flexibility. As proven experts in quantitative as well as qualitative methods, we have mastered the entire spectrum of market research. Equipped with specialized know-how and insight into consumer needs and behaviour and industries we develop innovative solutions tailored to our clients' individual needs. The result? Facts you can rely on, practical tools, and clear business recommendations, all of which are critical in helping our clients achieve their business objectives.
    Our success in the market speaks for itself. Our roster of clients includes some of the top international companies, as well as innovative start-ups that are setting new standards in their industries. The rapid growth of our business is mainly the result of satisfied clients who continue to expand their partnerships with us and refer us to others.
    Sound interesting? Then get in touch! Or perhaps you'd like to become a FactWorker yourself?
    Teamwork is our strength. We take an interdisciplinary approach at Factworks, providing our partners with a team of economists, psychologists, statisticians, and engineers.
    All our professional consultants hold a master's or doctorate degree and were top graduates in their classes. Prior to joining Factworks, many of our associates worked in companies on the client side, with other market research institutions, or in the consulting business.
    Through industry conferences and continuing education at the university level, associates at Factworks are continually learning about the newest trends in marketing and industry, and the most up-to-date statistical methods. This wide-ranging mix of competencies guarantees innovative and precise solutions for our customers.
    Axel Bichler is the founder and managing director of Factworks. He studied engineering economics in Jena and Berlin, performed research on conjoint analysis, and worked as a marketing and modeling expert at Daimler AG's think tank, the Society Research Group. For almost 20 years he's helped companies to make the right decisions, using methodologically sophisticated market research studies.
    In addition to executive management and leadership of exciting projects, he's enthusiastic about statistical methods – especially those involving conjoint analysis. He is a renowned expert in this field, lectures regularly at universities, and runs seminars in Germany at home and abroad.
    When not at Factworks, Axel Bichler likes spending time with his family, listening to music, using gadgets like the indispensable iPhone, and cooking. He runs to compensate for the last of these.
    Prof. Marcel Paulssen is cofounder of Factworks and professor of marketing at the Business School at the University of Geneva. As our scientific advisor, he ensures that Factworks has access to the latest marketing knowledge and ideas, and helps us to develop innovative solutions for our customers. His primary research interests lie in customer relationships, brand management, and positioning and segmentation. He is a renowned expert on multivariate techniques and has a special passion for structural equation models.
    Prior to his appointment at the University of Geneva, he was a visiting professor at the Ross School of Business at the University of Michigan, and professor at Humboldt University in Berlin. He gained practical experience in strategic marketing at Matsushita in Tokyo, and at the Society and Technology Research Group, a Daimler AG think tank. He has been honored repeatedly for his academic achievements, and his research is published in leading international marketing journals.
    When Prof. Paulssen gets time away from the computer, he's a sports enthusiast and enjoys cultural events in Berlin and Geneva.
    We want to captivate our clients, and our associates, too! The creation of an inspiring and motivating work environment is essential to delivering excellent market research to our clients. This is a core value by which we operate. Every day.
    Fairness, integrity, and reliability are the pillars for building trustworthy, long-term relationships with customers, coworkers, and partners.
    Only the very best solution will do for our customers. It's our obligation as passionate market researchers. Excellence in market research and analytics is reflected consistently in all of our services – with no ifs, ands, or buts.
    Openness to new recommendations, ideas, and criticisms leads to continual improvement and genuine innovation. That's why we foster a culture of open communication – internally as well as externally.
    A strong team sticks together. We're partners and value each other at all levels of our work with one another. It's this team spirit that promotes a motivating work environment, a place where work is fun.
    Exciting projects with leading international companies, innovative research methods, industry insights, advanced professional development and attendance at professional conferences, and close contact with one of Switzerland's most prestigious business schools. Add to that a great team and a dynamic, intellectually stimulating work environment in one of the world's most attractive cities. All in one package! Start your dream career at Factworks!
    Factworks is an international high-end market research and analytics company with offices in Berlin and San Francisco and with an academic connection to the Business School at the University of Geneva. We work with international industry leaders and innovative start-ups. Our customers are from eCommerce and online trading, information and communication technology, the automotive and consumer goods industries, and financial services. A substantial number of our clients is based in other European countries and the United States.
    Here at Factworks you can fully explore your passion for market research. You'll start working on domestic and international projects as part of a research team, addressing our clients' market research questions, and soon you'll be able to assume responsibility. As an associate at our mid-size, owner-driven company, you'll greatly benefit from flat management hierarchies, and many opportunities to contribute your ideas and see them come to life.
    Sound exciting? Are you intrigued to become a FactWorker? We look forward to hearing from you and reviewing your résumé. Still have questions? Give us a call, send us an email, or Skype us!
    Global industry leaders, DAX-30 and Fortune 500 companies, as well as innovative start-ups rely on our market research competence and expertise in Germany, Europe and the United States. One in two of our projects have an international context. The map below shows the countries in which Factworks has already conducted research project for our clients.
    Already right after its formation Factworks has started to serve international clients with a focus on companies based in the Silicon Valley. In order to better serve our growing customer base in the Valley and North America we have decided to open our new office in San Francisco in 2012.
    The steady growth of our business is almost exclusively the result of satisfied clients who continue to expand their partnerships with us and refer us to others. Since we respect the privacy of our clients, the list below only shows a selection of our current client list:
    As a full-service company, we guide you throughout your market research project – from the qualitative preliminary study, to the quantitative main study, and on through our specific recommendations for management and implementation. An experienced senior research consultant is in charge of your project through all its phases. This gives you the advantage of project leaders who are well acquainted with all aspects and ramifications of a project, and a partner who can competently respond to any of your questions. Our customers testify to how well our concept functions: Factworks delivers all-around hassle-free projects with consistently high service at all project phases. Always.
    Defining the problem is the most crucial factor to ensure successful of market research projects. That's why we invest substantial time to understand your business question and identify the appropriate research questions. This ensures that you receive the exact information you need to make the right decisions.
    You won't find any ready-made solutions here. We select the method that answers your questions validly and in the best way. It doesn't always require a highly complex process. If a simple contingency table is all it takes, we'll solve the problem with a contingency table.
    We truly value transparency. That doesn't simply mean we are happy to discuss our methods with you, but also that we regularly inform you of the current status of your project, without waiting for you to ask.
    We organize our presentations so that you understand things at first glance. Even complex relationships can be simply and clearly displayed with good visuals.
    We give you specific answers to your project questions and derive clear recommendations for courses of action, marketing, and management decisions. Equipped with the facts we provide for you, you'll be better prepared to make successful decisions.
    It often happens that customers wish to partially or completely do their own market research projects, perhaps because of budget restrictions or other strategic considerations. Selective support provided by an external market research service provider ensures the quality of such a project.
    Factworks is there for you in cases exactly like the one mentioned above. We'll support you in those specific project phases in which you particularly need our expert knowledge. Precisely and efficiently. Regardless of which phase of a project you may find yourself in or what type of support you require from us, you can pick out the goodies you need from our service portfolio.
    Sound familiar? You're at the airport and boarding has already begun. The security lines are long, but you absolutely must catch your flight. You may run into a similar situation with a market research project: Sometimes our clients need fast and highly pertinent results, whether for a management meeting, a board meeting, a product launch, or an imminent acquisition.
    That's why we offer our clients a Fast Track option. We help you win your race against time. With our expertise and flexibility, we've positioned ourselves to implement projects quickly and efficiently. And of course we're committed to maintaining our Factworks standard of quality and precision. Just like the Fast Track at the airport, our Fast Track service is no less thorough than the normal track. Because sloppiness is unacceptable in either place.
    Online communities are becoming more instrumental in the innovation process, because after all, who knows your products better than the users? Online group discussions, idea competitions, co-creation, online diaries, prediction markets, even classic online surveys – all provide a plethora of opportunities for applying this extensive consumer know how to your new product development, during every phase of the innovation process.
    Factworks is at your side during the setup, management, and further development of your online community. Our experienced experts will advise you on all matters of community management, and will support you with the planning, implementation, and analysis of qualitative and quantitative studies of your customer community. Of course, we also offer this service for employee innovation communities.
    No matter if your goal is to become acquainted with the fundamentals of market research, to gain an overview of the most important analytical processes and their applications, or to delve deeper into a specific method, Factworks provides suitable training. In a structured group workshop, we ascertain your goals and requirements, and develop a training program that is perfectly tailored to you and your co-workers.
    We also provide consulting on fundamental methodological questions or the optimization of your market research. Our clients regularly rely on our expert advice when it comes to complex research designs and choosing the appropriate methodology.
    As part of the optimization of your market research, we'll take an in-depth inventory of your market research activities and procedures. We'll build on that inventory to determine how your company can optimize its strategic market research plans. We'll also advise you during the operational implementation and budget allocation.
    The ranking of the world's top 100 brands continues to provide us with new and impressive insight each year. Brands are the most valuable asset for many companies.
    Successful brands become part of consumers' daily lives, are reflected in their opinions, convictions, and images of themselves and the world. Comprehensive understanding of these customer perceptions is essential for successful brand management. Only when the impressions and images that drive brand equity are identified and continually monitored, can a company strategically position its brand and continue to develop its value.
    We provide you with a comprehensive repertoire of quantitative and qualitative methods and instruments to analyze and understand brands in a holistic way. Factworks is a pioneer in the use of structural equation models to quantify how brand imagery influences purchasing behavior and brand equity. We can point to numerous successful brand-tracking studies that investigated up to 12 countries simultaneously.
    Our core competencies in brand management are:
Brand equity and measurement of brand image awareness
Analysis of brand equity drivers
Tracking of brand equity and image awareness
Brand/funnel analyses
We provide you with consulting not only on issues of brand equity management, but also in the areas of brand architecture and future brand expansion.
    Customer relationship management, the careful building and fostering of long-term customer relationships, is one of the key activities in marketing today.
    Its goal is to create a positive customer experience along all the touch points at which the customer is engaged, in order to achieve a high level of satisfaction, and, ideally, an emotional bond with the company. Satisfied customers become loyal customers or even enthusiastic ambassadors for a brand or product. This can be achieved by better understanding the main drivers of satisfaction and loyalty. The better you understand your customers' expectations at various touch points and their current evaluation of these, the better chance your company has to take the most appropriate measures at each stage of the customer cycle.
    Factworks offers comprehensive experience in the modeling of customer relationships. Our innovative methods and solution-sets for all aspects of customer management have been presented at international conferences and published in scientific journals. Equipped with this expertise, we identify the relevant drivers of customer satisfaction and loyalty, support you in the implementation of the Net Promoter Score (NPS), and develop effective measures to prevent customer churn. One of our core competencies in this service area is the ability to manage large scale multinational studies – for example in more than 100 countries simultaneously.
    The development of new products and services is vital to a company's survival, yet it presents one of the most cost-intensive and high-risk initiatives a company must face.
    The investment of millions in R&D is typically accompanied by massive uncertainties as to whether the investment will translate into sales and profitability, and the danger of failure in the market is high. With an Open Innovation Strategy, companies integrate consumers to a much greater degree into the product development process. Web 2.0, with social media and online customer communities, offers product developers new opportunities, and they can expand on "classic" methods for (new) product research, such as conjoint analyses.
    Factworks supports you in all phases of new product development – from concept to prototype evaluation, and on through to market delivery. You profit from our dual qualification as quantitative and qualitative market researchers. We deliver on both sides of the equation: We build your online customer community and assume responsibility for its management, as well as act as your expert partner in co-creation, and all market research processes during the product development phase. Among our core competencies are conjoint and discrete choice analyses. Whether it's an adaptive or menu-based conjoint , we've mastered all the variants, and we're developing market simulators that enable us to measure diverse product concepts. We deliver the findings that you need to make your product ideas a success.
    Pricing policy is the single most important instrument in the marketing mix, because pricing decisions directly impact sales and profits.
    Still, the importance of price optimization is often given short shrift. Many companies assign prices based on simple surcharge calculations, or rely on their intuition rather than well-founded analytics. The result: suboptimal pricing decisions that make the company miss out on potential profit.
    We at Factworks pride ourselves with being price research experts. We understand all the variants of conjoint and discrete choice analyses that are central to pricing research – from the classic to the most modern, such as ACBC and MBC – and also developed our own proprietary software. Armed with this expertise, we are the ideal partner for pricing research questions of all kinds. We'll develop your optimal pricing model based with respect to pricing components, price levels, and bundle offers, and derive the appropriate pricing strategy.
    Customers are diverse: They differ in their purchase motivations, needs, and product expectations.
    Strong marketing segmentation enables companies to better understand the heterogeneity of their markets, to identify promising target groups, and to create a marketing mix that speaks specifically to consumers within those differentiated groups. Market segmentation is among the most elementary market research projects, since it's the foundation on which a company builds its marketing strategy.
    Factworks offers the most important elements for successful segmentation projects. Our experts have excellent knowledge of markets and customers from the most diverse industries. We have mastered the newest statistical processes, such as the latent class model, and have executed a wide range of segmentation projects. As qualitative and quantitative market researchers, we guide and support you throughout the segmentation process – from hypothesis development, to the final segmentation, and on through the implementation planning.
    The result? A robust and practical customer segmentation that constitutes a real competitive advantage for your company.
    Usage and attitude studies establish a basic understanding of the market structure, competitive landscape and customer behaviour and needs.
    These are important fundamental research studies: Qualitative and quantitative methods are used to ascertain the needs of product users (and nonusers), how they use the product, as well as their attitudes toward your products and those of your competitors. They also identify market gaps and market trends.
    Who uses which product, how, when, and in what context? What are the needs and motives of those who use it? Who doesn't use this product, and why? What barriers inhibit potential users and how do you remove them? We offer clear answers to each of these questions. The results of our U&A studies will give you a better understanding of your those who are your customers and those who aren't, enabling you to turn your market oppo relationships, trends, and behavioral patterns contained within your data. We specifically provide support in the area of churn management, in selecting your target groups for advertising campaigns, conducting customer segmentation, and eventually transform your data into competitive advantages. Whenever possible, our specialists link primary data with internal company data to provide integrated answers to key project questions. Internal customer data is critical because it reveals actual customer behavior and extends the primary data, enabling us to draw accurate conclusions.
    We are an interdisciplinary team with diverse and specialized backgrounds, but we all share a special passion for empirical research and statistics.
    We have mastered the classical procedures as well as the newest statistical methods. By employing structural equation models and PLS, we are one of the pioneers in market research.
    By employing structural equation models and PLS, we are one of the pioneers in market research. In the area of conjoint and discrete choice analyses, our competencies range from the classical to the very newest developments.
    We are continuously providing further training for our associates, to make them into true experts in one or more methods. Equipped with this knowledge, we can then determine very precisely which method is optimal for the questions you need answered.
    Good market research requires not only hard but also soft methods.
    In order to better understand and illustrate the complexities of a problem, you have view it from several perspectives. Whether with a focus group or in-depth interviews, Factworks can provide you with a repertoire of qualitative methods – offline as well as online. Dr. Beate Müller, former head of Daimler AG's Holistic and Intercultural Customer Needs research group, and a renowned expert with many years of experience in qualitative market research, is part of the Factworks team. We'll help you better understand your customers!!
    The dizzying growth rates of the eCommerce market continue to attract new companies. Competition for securing long-term customers has generated highly customized worlds of experience for online shoppers.
    Thus the eCommerce industry profits from the rapid speed of innovation in the ICT field, and makes it possible for new business models to spring up. Mobile and social commerce will soon bring eCommerce to the next level and will further fuse the real and digital worlds.
    Factworks expanded in eCommerce along with its clients. We have accompanied and consulted with some of the biggest players, from the first generation of our start-up to the present day. At the same time, we work with innovative start-ups, and therefore have our finger on the pulse of our industry. A large portion of our eCommerce projects are directly commissioned and coordinated from company headquarters in the United States. This is further evidence of our competence and quality of work.
    We offer our customers a broad spectrum of service options, from strategic projects such as market entry of a leading social network site in Germany, to price optimization for an online marketplace or the development of trust models for a trading platforhink tank of Daimler, Inc. – Factworks is "pumped up" about cars. The experts on our automotive teams have years of experience in the automotive industry. Whether you're in the quantitative or qualitative area of automotive market research, we assure you that our recommendations are both relevant and built on firm and precise facts from the most experienced of sources.
    We understand your customers' approach and what drives them, as well as the long-term development of brand and satisfaction performance. From that knowledge base we create and deliver the outstanding strategy that your company needs in order to guarantee successful customer and brand management. We can also provide you with support in the area of vehicle concept, equipment types and specification, pricing, and service performance – all tailored to your customers' needs.
    We specialize in the following areas within the automotive industry:
    Customer loyalty and customer satisfaction/CSI
New product design and pricing
Quality assurance
Positioning and brand image
The boom of discounters and the increased competition in the retail market have for years put increased pressure on consumer goods manufacturers. Even best-established companies are finding it more difficult to charge premium prices.
    What also accelerates this development is the lack of consumer willingness to pay those prices, even though they have higher expectations of product quality. The constantly changing needs of consumers present a further challenge to FMCG companies: The hybrid customer shops not only at discounters, but also at high-end retail stores. This makes a valid prediction of buyer behavior rather difficult.
    Factworks supports consumer goods manufacturers in developing a deeper understanding of their ou. We're glad to have you!`

const colorSchemes = [
    {
      index: 0,
      label: "Factworks",
      colors: ['#00abff', '#0f23a4', '#0f41d7', '#005fff', '#000a64'],
    },
    {
      index: 1,
      colors: ['#00b9ae', '#24f0c6', '#037171', '#03312e', '#009f93'],
    },
    {
      index: 2,
      colors: ['#eac435', '#ff7240', '#345995', '#03cea4', '#ca1551'],
    },
    {
      index: 3,
      colors: ['#442220', '#000028', '#809848', '#b0ca87', '#b5dead'],
    },
    {
      index: 4,
      colors: ['#d3bdb0', '#465a64', '#59544b', '#c1ae9f', '#89937c'],
    },
    {
      index: 5,
      colors: ['#bee9e8', '#465a64', '#62b6cb', '#cae9ff', '#5fa8d3'],
    },
    {
      index: 6,
      label: "Facebook 1",
      colors: ['#003aab', '#0258ff', '#9abcff', '#bcd3ff', '#dde9ff'],
      private: true,
    },
    {
      index: 7,
      label: "Facebook 2",
      colors: ['#33434a', '#4d646f', '#a3b7bf', '#c1cfd5', '#e0e7ea'],
      private: true,
    },
    {
      index: 8,
      label: "Paypal 1",
      colors: ['#006849', '#009b6d', '#49ffc9', '#86ffdb', '#c2ffed'],
      private: true,
    },
    {
      index: 9,
      label: "Paypal 2",
      colors: ['#6f0031', '#a6004a', '#ff529f', '#ff8cbf', '#ffc5df'],
      private: true,
    },

    {
      index: 10,
      label: "Paypal 3",
      colors: ['#004e6f', '#0075a6', '#009cde', '#8cddff', '#c5eeff'],
      private: true,
    },
    {
      index: 11,
      colors: ['#000000']
    },
]

const linearHues = [200, 167, 7, 51, 276, -1];

const fonts = [
  'Courier',
  'Fredoka One',
  'Georgia',
  'Helvetica',
  'Impact',
  'Leckerli One',
  'Lobster',
  'Molle',
  'Montserrat',
  'Nanum Gothic',
  'Oleo Script',
  'Open Sans',
  'Pacifico',
  'Share Tech Mono',
  'Skranji',
  'Times New Roman',
  'Verdana',
];

const privateFonts = [
  'Factworks'
]

const defaultSettings = {
  maxWords: 225,
  minFrequency: 1,
  rotationPoints: 3,
  rotationRange: [-45, 45],
  scale: 'sqrt',
  wordLayout: 'rectangular',
  font: 'Georgia',
  colorScheme: colorSchemes[0],
  colorLinear: -1,
}

const cloudStyle = `
@import url('https://fonts.googleapis.com/css?family=Fredoka+One|Leckerli+One|Lobster|Molle:400i|Oleo+Script|Pacifico|Share+Tech+Mono|Skranji');

font-family: 'Lobster', cursive;
font-family: 'Oleo Script', cursive;
font-family: 'Share Tech Mono', monospace;
font-family: 'Fredoka One', cursive;
font-family: 'Skranji', cursive;
font-family: 'Pacifico', cursive;
font-family: 'Leckerli One', cursive;
font-family: 'Molle', cursive;
font-family: 'Montserrat', cursive;
font-family: 'Nanum Gothic', cursive;
font-family: 'Open Sans', cursive;
`

export {defaultText, colorSchemes, linearHues, fonts, privateFonts, cloudStyle, defaultSettings}
