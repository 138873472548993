
import React from 'react';

import { Grid, Typography } from '@material-ui/core';

class PropResults extends React.Component {

  /* Creates a visualisation of Proportion Test results as two circles */

  render(){

    /* Set size of circles based on results (defaults to equal size)
       and comparison text (smaller, bigger or the same)
    */

    var comparison_text;

    let circle_a_class = "r-circle r-circle-blue";
    let circle_b_class = "r-circle r-circle-green";

    if (this.props.type === 'ind'){
      comparison_text = 'Sample A is not significantly different from Sample B'
    } else {
      comparison_text = 'Variable A is not significantly different from Variable B'
    }

    if (this.props.z_value < (this.props.expected_z_value * -1)){
      // circle_a = '70';
      // circle_b = '100';
      circle_a_class += ' r-circle-small'
      circle_b_class += ' r-circle-big'
      if (this.props.type === 'ind'){
        comparison_text = 'Sample A is significantly different from sample B';
      } else {
        comparison_text = 'Variable A is significantly different from Variable B';
      }
  } else if (this.props.z_value > this.props.expected_z_value){
      // circle_a = '100';
      // circle_b = '70';
      circle_a_class += ' r-circle-big'
      circle_b_class += ' r-circle-small'
      if (this.props.type === 'ind'){
        comparison_text = 'Sample A is significantly different from Sample B';
      } else {
        comparison_text = 'Variable A is significantly different from Variable B';
      }
    } else {
      circle_a_class += ' r-circle-big'
      circle_b_class += ' r-circle-big'
    }

    comparison_text += ' at a significance level of ' + this.props.sig + '%';

    // Render the circles

    return (
      <Grid
        container
        direction="row"
        spacing={16}
      >

      <Grid item xs={12}>
        <Typography component="div" id="sig-result-title" variant="h3" align="center">
          RESULT
        </Typography>
      </Grid>

      <Grid item xs={6} id="circle-a-container">
        <div className={circle_a_class}>
          <span className="character_a">A</span>
        </div>
      </Grid>

      <Grid item xs={6} id="circle-b-container">
        <div className={circle_b_class} >
          <span className="character_b">B</span>
        </div>
      </Grid>

      <Grid item xs={12}>
        <p className="sig-medium-text">{ comparison_text }</p>
      </Grid>

      <Grid item xs={6} className="pr-2">
        <div id="sig-result-values-left w-100">
          <Typography component="span" align="center" variant="subtitle1">
            <b>z-Value:</b><br/>
            {this.props.z_value}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6} className="pl-2">
        <Typography component="span" align="center" variant="subtitle1">
          <b>p-Value:</b><br/>
          {this.props.p_value < 0.001 ? "< .001" : String(this.props.p_value).replace(/^0+/, '')}
        </Typography>
      </Grid>
     </Grid>
     )
  }
}

export default PropResults
