import React from "react";

import { Route, Router, Switch } from "react-router-dom";

import Home from "./Home";
import Download from "./translator/Download";
import Callback from "./lib/callback";
import LoginBox from "./lib/Login";
import Auth from "./lib/auth";
import NotFound from "./lib/NotFound";

import history from "./lib/history";

import { CONFIG } from "./etc/config.js";
import APP_CONFIG from "./etc/apps.js";

import Container from "./lib/Container";
import ReactGA from "react-ga";

const auth = new Auth();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

if (CONFIG.MODE === "production") {
  ReactGA.initialize(CONFIG.GOOGLE_ANALYTICS.key);
}

const gaPageview = () => {
  if (CONFIG.MODE === "production") {
    ReactGA.pageview(window.location.pathname);
  }
};

export const makeMainRoutes = () => {
  let app_routes = [];
  let app_group_routes = [];
  const app_groups = Object.keys(APP_CONFIG);

  for (let v in app_groups) {
    const key = app_groups[v];
    const group = APP_CONFIG[key];

    app_group_routes.push(
      <Route
        exact
        path={group.path}
        render={props => (
          <Container
            gaPageview={gaPageview}
            auth={auth}
            name={group.name}
            public={group.public}
            group={group}
            main={Home}
            {...props}
          />
        )}
      />
    );

    app_routes.push(
      APP_CONFIG[key].apps.map(
        app =>
          app.active && (
            <Route
              path={app.path}
              render={props => (
                <Container
                  gaPageview={gaPageview}
                  auth={auth}
                  public={app.public}
                  name={app.name}
                  app={app}
                  main={app.component}
                  {...props}
                />
              )}
            />
          )
      )
    );
  }

  return (
    <Router history={history}>
      <div>
        <Switch>
          {app_group_routes}

          {app_routes}

          <Route
            path="/login/"
            render={props => <LoginBox login={auth.login} {...props} />}
          />

          <Route
            path="/download/:job_id"
            render={props => (
              <Container
                gaPageview={gaPageview}
                auth={auth}
                main={Download}
                {...props}
              />
            )}
          />

          <Route
            path="/callback"
            render={props => {
              handleAuthentication(props);
              return <Callback {...props} />;
            }}
          />

          <Route render={props => <NotFound />} />
        </Switch>
      </div>
    </Router>
  );
};
