import { CONFIG } from '../../etc/config'
import { wrapURL } from '../../lib/Helpers.js'

export default class API {

  sendRequest(params, file){

    this.setState({error: null})

    var query_url = wrapURL(CONFIG.API.base + 'translator/upload/')
    var formData = new FormData();
    formData.append('parameters', JSON.stringify(params))
    formData.append('file', file)

    fetch(query_url, {
      method: "POST",
      headers: {},
      body: formData,
      })
      .then(response => response.json())
      .then(response => this.setState({response: response}))
      .catch((error) => {this.setState({error: error})})
  }

  downloadFile(job_id){
    let download_url = CONFIG.API.base + 'translator/download/' + job_id;
    const access_token = localStorage.getItem('access_token');
    const id_token = localStorage.getItem('id_token');
    download_url += '?id_token=' + id_token + '&access_token=' + access_token;
    window.location.href = download_url;
  }

 }
