import React, { Component } from "react";
import ReactWordCloud from "./lib/ReactWordCloud";

import { copyDict, pickRandom, randomBetween } from "../lib/Helpers.js";
import { Grid, Paper } from "@material-ui/core";

import debounce from "lodash.debounce";

import API from "./lib/api";
import { exportPNG, exportSVG } from "./lib/exportGraph.js";
import {
  defaultText,
  colorSchemes,
  linearHues,
  fonts,
  privateFonts,
  defaultSettings
} from "./lib/defaults.js";

import ControlPanel from "./lib/ControlPanel.js";
import PreviewFile from "./lib/PreviewFile.js";
import LinearCloud from "./lib/LinearCloud.js";
import { SelectFileInput } from "./lib/FileInput.js";

import MediaQuery from "react-responsive";
import SideTabs from "./SideTabs.js";

import "./assets/css/Wordcloud.css";

/**
 * WordCloud
 * Main component for rendering Wordcloud
 */

class WordCloud extends Component {
  constructor(props) {
    super(props);
    this.API = new API();
    this.getWordList = this.API.getWordList.bind(this);

    const LOGGED_IN = this.props.auth.isAuthenticated();

    this.state = {
      originalAllWords: [],
      allWords: [],
      cloudType: "cloud",
      text: defaultText,
      settings: copyDict(defaultSettings),
      panel: 1,
      fonts: LOGGED_IN ? privateFonts.concat(fonts) : fonts,
      colorSchemes: LOGGED_IN
        ? colorSchemes
        : colorSchemes.filter(s => !s.private),
      count: 0
    };

    this.updateText = this.updateText.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.updateExcludedWords = this.updateExcludedWords.bind(this);
    this.getDisplayWords = this.getDisplayWords.bind(this);
    this.updateWordList = this.updateWordList.bind(this);
    this.reset = this.reset.bind(this);
    this.shuffle = this.shuffle.bind(this);
    this.random = this.random.bind(this);
    this.setWordlist = this.setWordlist.bind(this);
    this.handleMerge = this.handleMerge.bind(this);
    this.handleUnmerge = this.handleUnmerge.bind(this);
    this.exportSVG = exportSVG;
    this.exportPNG = exportPNG;
    this.saveFile = this.saveFile.bind(this);
    this.setUploadType = this.setUploadType.bind(this);
    this.loadPanel = this.loadPanel.bind(this);
    this.sortFunc = this.sortFunc.bind(this);
    this.linearCloudRef = React.createRef();
  }

  componentDidMount() {
    this.getWordList(defaultText, this.sortFunc);
    window.addEventListener("resize", debounce(this.shuffle, 300));
  }

  isLoggedIn = () => {
    return this.props.auth.isAuthenticated();
  };

  updateSetting(setting, value) {
    /* Update a setting */
    if (setting === "maxWords" && value < 99) {
      this.setState({ delay: 2000 });
    } else {
      this.setState({ delay: 0 });
    }

    if (setting === "font" && value === "") return;

    const NUMBER_INPUTS = ["maxWords", "minFrequency", "rotationPoints"];
    if (
      NUMBER_INPUTS.indexOf(setting) > -1 &&
      ([0, ""].indexOf(value) > -1 || isNaN(value))
    )
      value = 1;

    let settings = this.state.settings;
    settings[setting] = value;
    this.setState({ settings: settings }, () => this.shuffle());
  }

  loadPanel(id) {
    /* Load the panel view */
    this.setState({
      panel: id
    });
  }

  saveFile(file, binary) {
    /* Store the file in state */
    this.setState(
      {
        file: file,
        binary: binary
      },
      () => this.loadPanel(2)
    );
  }

  setUploadType(uploadType) {
    /* Change the upload type */
    this.setState(
      {
        uploadType: uploadType
      },
      () => this.loadPanel(3)
    );
  }

  updateExcludedWords(callback, { word, freq }) {
    let allWords = this.state.allWords
    let word_obj = allWords.filter( w => (w.word === word) && (w.value === freq))[0]

    if(word_obj.stopword) {
      word_obj['stopword'] = false
      word_obj['hidden']   = false
    } else {
      word_obj["hidden"] = !word_obj["hidden"] // flip flag
    }

    this.setState({
      allWords: allWords
    });
    callback();
  }

  updateText(newText) {
    /* Update text and generate new list */
    if (newText.length === 0) {
      this.setState({
        text: "",
        originalAllWords: [],
        allWords: []
      });
    } else {
      this.setState({ text: newText }, () => {
        this.getWordList(this.state.text, this.sortFunc);
      });
    }
  }

  handleUnmerge(word) {
    var allWords    = this.state.allWords;

    //finding index to splice list later on
    const mergedIndex = allWords.findIndex( (w) => {
      return (w.word === word)
    })
    const mergedObj = allWords[mergedIndex];
    const mergeOf   = mergedObj.mergeOf;

    // remove merged object from list
    allWords.splice(mergedIndex, 1);

    if (mergeOf) {
      // add separate words back to position
      for (let i in mergeOf) {
        let obj      = mergeOf[i];
        let val      = obj.value;
        let newIndex = allWords.findIndex( (element) => {
          return element.value <= val;
        });
        allWords.splice(newIndex, 0, obj);
      }
      this.setState({ allWords: allWords })
    }
  }

  handleMerge(toRemove, newEntry) {
    var allWords = this.state.allWords;

    // remove merged objects
    allWords = allWords.filter( (w) => {
      return !toRemove.includes(w);
    });

    // find first less-frequent element
    const newEntryIndex = allWords.findIndex( (element) => {
      return element.value <= newEntry.value ;
    });

    allWords.splice(newEntryIndex, 0, newEntry);

    this.setState({ allWords: allWords });
  }

  setWordlist(list) {
    /* Set word list directly */
    let allWords = Array.from(list)

    this.setState({
      originalAllWords: list,
      allWords: allWords
    });
  }

  sortFunc(list) {
    return list.sort((a,b)=>{
      if(b.value !== a.value) {
        return b.value - a.value
      } else {
        return (a.word.toLowerCase() < b.word.toLowerCase()) ? -1 : 1
      }
    })
  }

  updateWordList(callback, { prevState, updated }) {
    /* Update properties of a word in the word list */
    var wordList = this.state.allWords;
    const wordDict = wordList.find(w => {
      return w.word === prevState.word && w.value === prevState.freq;
    });
    const changedKeys = Object.keys(updated);
    for (var i in changedKeys) {
      let key = changedKeys[i];
      if (key === "freq") {
        wordDict["value"] = Number(updated[key]);
      } else {
        wordDict[key] = updated[key];
      }
    }
    this.setState({ originalAllWords: wordList }, () => callback());
  }

  reset() {
    /* Reset the wordcloud to default settings */
    this.setState(
      {
        settings: copyDict(defaultSettings),
        text: defaultText,
        excludedWords: []
      },
      () => this.getWordList(defaultText, this.sortFunc)
    );
  }

  shuffle() {
    /* Shuffle the wordcloud */
    this.setState((state, props) => ({
      settings: state.settings
    }));
  }

  random() {
    /* Generate random wordcloud settings */
    this.setState(
      (state, props) => ({
        settings: {
          colorScheme: pickRandom(this.state.colorSchemes),
          colorLinear: pickRandom(linearHues),
          font: pickRandom(this.state.fonts),
          rotationPoints: randomBetween(0, 10),
          rotationRange: [-1 * randomBetween(0, 90), randomBetween(0, 90)],
          scale: pickRandom(["sqrt", "linear"]),
          wordLayout: pickRandom(["rectangular", "archimedean"]),
          maxWords: state.settings.maxWords,
          minFrequency: state.settings.minFrequency
        }
      }),
      () => setTimeout(() => this.shuffle(), 500)
    );
  }

  sortWordList(list) {
    return list.sort((a,b)=>{
      if(b.value !== a.value) {
        return b.value - a.value
      } else {
        return (b.word < a.word) ? -1 : 1
      }
    })
  }

  getDisplayWords() {
    /* Generate display words from original word list */
    const {
      allWords,
      settings: { maxWords, minFrequency }
    } = this.state;

    let displayWords = allWords.filter(
      d => (!d.stopword) && (!d.hidden) && (d.value >= minFrequency)
    );

    return displayWords.slice(0, maxWords);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.delay > 0) {
      setTimeout(() => {
        this.setState({ delay: 0 });
      }, nextState.delay);
      return false;
    }
    return true;
  }

  render() {
    const params = {
      words: this.getDisplayWords(),
      wordCountKey: "value",
      wordKey: "word",
      font: this.state.settings.font,
      colorScheme: this.state.settings.colorScheme.colors,
      rotationRange: this.state.settings.rotationRange,
      wordCloudRef: React.createRef(),
      scale: this.state.settings.scale,
      wordLayout: this.state.settings.wordLayout,
      orientations: this.state.settings.rotationPoints
    };

    let cloudWidth, cloudHeight;
    if (window.innerWidth > 1300) {
      cloudWidth = window.innerWidth < 2000 ? 0.6 * window.innerWidth : 1000;
      cloudHeight = 0.75 * window.innerHeight;
    } else if (window.innerWidth > 750) {
      cloudWidth = 0.87 * window.innerWidth;
      cloudHeight = 0.7 * window.innerHeight;
    } else {
      cloudWidth = 0.87 * window.innerWidth;
      cloudHeight = window.innerHeight > 1000 ? 0.7 * window.innerHeight : 600;
    }

    const reactWordCloud = (
      <ReactWordCloud
        words={params.words}
        wordCountKey={params.wordCountKey}
        wordKey={params.wordKey}
        fontFamily={params.font}
        colors={params.colorScheme}
        minAngle={params.rotationRange[0]}
        maxAngle={params.rotationRange[1]}
        orientations={params.orientations}
        ref={params.wordCloudRef}
        scale={params.scale}
        spiral={params.wordLayout}
        width={cloudWidth}
        height={cloudHeight}
        tooltipEnabled={false}
      />
    );

    const linearCloud = (
      <LinearCloud
        data={params.words}
        font={this.state.settings.font}
        color={this.state.settings.colorLinear}
        ref={this.linearCloudRef}
        width={cloudWidth}
        height={cloudHeight}
      />
    );

    const selectFileInput = (
      <SelectFileInput
        file={this.state.file}
        setUploadType={this.setUploadType}
      />
    );

    const previewFile = (
      <PreviewFile
        file={this.state.file}
        binary={this.state.binary}
        updateText={this.updateText}
        setWordlist={this.setWordlist}
        loadPanel={this.loadPanel}
        type={this.state.uploadType}
        setPanel={this.setPanel}
      />
    );

    const cloudType = this.state.cloudType;
    const cloud = cloudType === "cloud" ? reactWordCloud : linearCloud;

    // Decide what to show in main area
    let main;
    switch (this.state.panel) {
      default:
        break;
      case 1:
        main = cloud;
        break;
      case 2:
        main = selectFileInput;
        break;
      case 3:
        main = previewFile;
        break;
    }

    const sideTabs = (
      <SideTabs
        updateSetting={this.updateSetting}
        settings={this.state.settings}
        text={this.state.text}
        updateText={this.updateText}
        saveFile={this.saveFile}
        wordList={this.state.allWords}
        displayList={params.words}
        handleMerge={this.handleMerge}
        handleUnmerge={this.handleUnmerge}
        updateWordList={this.updateWordList}
        updateExcludedWords={this.updateExcludedWords}
        setWordlist={this.setWordlist}
        fonts={this.state.fonts}
        colorSchemes={this.state.colorSchemes}
        cloudType={this.state.cloudType}
        unsetOrientation={() => this.updateSetting("rotationRange", [0, 0])}
        isLoggedIn={this.isLoggedIn}
      />
    );

    const svgType = () => {
      let svgType;
      const { font } = this.state.settings;
      if (this.state.fonts.indexOf(font) > -1 && font !== "Factworks") {
        svgType = "shape";
      } else {
        svgType = "text";
      }
      return svgType;
    };

    const controlPanel = (
      <ControlPanel
        reset={this.reset}
        shuffle={this.shuffle}
        random={this.random}
        exportPNG={this.exportPNG}
        exportSVG={() => this.exportSVG(svgType())}
        setCloud={() => this.setState({ cloudType: "cloud" })}
        setLinear={() => this.setState({ cloudType: "linear" })}
        cloudType={this.state.cloudType}
        hide={this.state.panel !== 1}
      />
    );

    return (
      /* TO DO: Refactor to avoid code duplication */
      <div className="h-100 w-95 mx-auto flex-1 d-flex">
        <MediaQuery minWidth={1300}>
          <Grid item container direction="row" className="flex-1 outer-wc mb-3">
            <Grid item container md={3}>
              {sideTabs}
            </Grid>
            <Grid item container className="flex-1" md={9}>
              <Paper className="border m-3 mr-0 ml-0 w-100 rounded">
                <div className="mr-3">{controlPanel}</div>
                <div className="ml-2 mr-2 mt-5">{main}</div>
              </Paper>
            </Grid>
          </Grid>
        </MediaQuery>

        <MediaQuery maxWidth={1299}>
          <Grid item container direction="row" className="flex-1 outer-wc mb-4">
            <Grid item container className="flex-1" md={12}>
              <Paper className="rounded mr-0 ml-0 w-100">
                <div className="mr-3">{controlPanel}</div>
                <div className="mt-3 ml-3">{main}</div>
              </Paper>
            </Grid>
          </Grid>
          {sideTabs}
        </MediaQuery>
      </div>
    );
  }
}

export default WordCloud;
