import React from 'react';

import Modal from 'react-modal';

const FModal = (props) => {

  var modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '30%'
    }
  };

  if (props.isMobile){
    modalStyles['content']['width'] = '80%';
    modalStyles['content']['height'] = '80%';
  }

  const BUTTON_STYLE = {
    position: 'absolute',
    top: '0',
    right: '0',
    border: '0',
    cursor: 'pointer'
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      style={modalStyles}
      contentLabel={props.contentLabel}
    >

      <button
        style={BUTTON_STYLE}
        onClick={props.closeModal}>Close
      </button>

      { props.children }

    </Modal>
  )
}

export default FModal;
