import React from "react";

import RSlider from "./lib/Slider.js";
import SubTitle from "./lib/SubTitle.js";
import CIGraph from "./lib/CIGraph.js";
import Tooltip from "./lib/Tip.js";
import Modal from "../lib/Modal.js";
import { ConfExample } from "./lib/Example.js";
import SubMenu from "./lib/SubMenu.js";

import jStat from "jstat";
import { Grid, Typography } from "@material-ui/core";

import "./assets/css/Sig.css";

const CIFooter = props => {
  return (
    <div id="ci_footer">
      <Typography id="ci_text" align="center">
        With <span className="live_value">{props.sig}%</span> certainty, the
        true population proportion will fall within the confidence interval
        of&nbsp;
        <span className="live_value">
          {Math.max((props.percentage - props.ci_value).toFixed(1), 0)}%
        </span>
        &nbsp;to{" "}
        <span className="live_value">
          {Math.min((props.percentage + props.ci_value).toFixed(1), 100)}%
        </span>
        .
        <span style={{ whiteSpace: "nowrap" }}>
          <br />
          <Typography>
            Need help? See{" "}
            <b
              onClick={props.openModal}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              examples
            </b>
            .
          </Typography>
        </span>
      </Typography>
    </div>
  );
};

class CIResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: 480
    };
    this.reposition = this.reposition.bind(this);
  }

  reposition() {
    let OFFSET = 0;
    if (window.innerWidth <= 400) {
      OFFSET = 75;
    } else if (window.innerWidth <= 600) {
      OFFSET = 50;
    } else if (window.innerWidth <= 1000) {
      OFFSET = 25;
    }
    this.setState({ position: 480 + OFFSET });
  }

  componentDidMount() {
    this.reposition();
    window.addEventListener("resize", this.reposition);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reposition);
  }

  render() {
    const { position } = this.state;

    const STYLE = { bottom: position.toString() + "px" };

    const { ci_tooltip, isMobile, ci_value } = this.props;

    return (
      <div id="ci_result" style={STYLE}>
        <span data-tip={ci_tooltip} id="ci_header">
          <Typography variant="body1"> Confidence Interval </Typography>
          <Tooltip
            title={"<big>" + ci_tooltip + "</big>"}
            position="bottom"
            trigger={isMobile ? "click" : "mouseenter"}
            arrow={true}
            size="big"
            style={{ cursor: "pointer" }}
          >
            <span style={{ fontSize: "0.6em" }}>
              &nbsp;<sup>[?]</sup>
            </span>
          </Tooltip>
        </span>

        <span id="ci_percentage_result">
          <span id="ci_plus_minus">+/-&nbsp;&nbsp;&nbsp;</span>
          {ci_value}%
        </span>
      </div>
    );
  }
}

class ConfidenceInterval extends React.Component {
  /* Main component for the Confidence Interval tool */

  constructor(props) {
    super(props);

    document.body.style.overflow = "auto";

    this.state = {
      percentage: 50,
      n: 300,
      n_min: 30,
      n_max: 10000,
      n_max_default: 10000,
      sig: 95,
      hide_graph: false,
      tip_type: "enter_or_slide",
      examples_modal: false,
      newvalue: 10
    };

    this.perc_handler = this.perc_handler.bind(this);
    this.n_handler = this.n_handler.bind(this);
    this.sig_handler = this.sig_handler.bind(this);
    this.calculate_ci_value = this.calculate_ci_value.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changed = this.changed.bind(this);
  }

  changed(value) {
    this.setState({ newvalue: value });
  }

  openModal() {
    this.setState({ examples_modal: true });
  }

  closeModal() {
    this.setState({ examples_modal: false });
  }

  calculate_ci_value(z_value, percentage, n_value) {
    var p = +(percentage / 100).toFixed(4);
    /* Calculates the Confidence Interval (CI) Value */
    var ci_value = z_value * Math.sqrt((p * (1 - p)) / n_value) * 100;
    return +ci_value.toFixed(2);
  }

  /* Handlers - update state based on user inputs */

  perc_handler(val) {
    /* Updates percentage value */
    this.setState({
      percentage: val
    });
  }

  n_handler(val) {
    /* Updates the value of n and n_max, if n exceeds default n_max */
    val = Number(val.toFixed(0));

    this.setState({
      n: val
    });

    if (val >= this.state.n_max) {
      this.setState({
        tip_type: "enter_larger"
      });
    } else {
      this.setState({
        tip_type: "enter_or_slide"
      });
    }

    if (val > this.state.n_max && val > this.state.n_max_default) {
      this.setState({
        n_max: val
      });
    } else if (
      val <= this.state.n_max_default &&
      this.state.n_max !== this.state.n_max_default
    ) {
      this.setState({
        n_max: this.state.n_max_default
      });
    }
  }

  sig_handler(val) {
    /* Updates sig, i.e. significance level */
    this.setState({
      sig: +parseFloat(val).toFixed(0)
    });
  }

  render() {
    /* Renders the component */

    var z_value = Math.abs(
      jStat.normal.inv(0.5 - this.state.sig / 100 / 2, 0, 1).toFixed(3)
    );
    var ci_value = this.calculate_ci_value(
      z_value,
      this.state.percentage,
      this.state.n
    );
    const ci_tooltip =
      "The Confidence Interval indicates a range of values within which the actual population proportion is likely to fall.";

    var isMobile = window.innerWidth <= 850;

    return (
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Grid container direction="row" spacing={16}>
          <Grid item xs={12}>
            <SubMenu currentApp="conf" />
          </Grid>

          <Grid
            item
            container
            spacing={16}
            className="calc-container mx-auto rounded p-4 mb-5"
          >
            <Grid item xs={12}>
              <SubTitle
                text="This calculator is used to find the confidence interval of an observed sample proportion given sample size and significance level. You can change the values by entering them directly below or moving the sliders."
                isMobile={isMobile}
              />
            </Grid>

            <Grid item xs={12}>
              <RSlider
                id="perc_slider"
                value={this.state.percentage}
                label="Observed Proportion"
                label_mobile="P"
                colour="blue"
                percentage={true}
                size={12}
                handler={this.perc_handler}
                tip={false}
                max={100}
                min={0}
                tooltip={isMobile ? "Observed Proportion" : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <RSlider
                id="n_slider"
                value={this.state.n}
                label="Sample Size"
                label_mobile="N"
                colour="blue"
                min={this.state.n_min}
                max={this.state.n_max_default}
                soft={true}
                size={12}
                handler={this.n_handler}
                tip={false}
                decimals={false}
                tooltip={isMobile ? "Sample Size" : ""}
              />
            </Grid>

            <Grid item xs={12}>
              <RSlider
                id="sig_slider"
                value={this.state.sig}
                label="Significance Level"
                label_mobile="Sig"
                colour="blue"
                percentage={true}
                size={12}
                sig={true}
                handler={this.sig_handler}
                tip={false}
                max={99}
                min={80}
                decimals={false}
                tooltip={
                  (isMobile ? "<big>Significance Level</big><br/>" : "") +
                  "<big>The level of confidence that the Confidence Interval will contain the true population proportion.</big>"
                }
              />
              {/* <Tip type={this.state.tip_type} slider_size="xlarge" isMobile={isMobile} /> */}
            </Grid>

            <Grid item xs={12}>
              <CIGraph
                n={this.state.n}
                percentage={this.state.percentage}
                ci={ci_value}
                z_value={z_value}
                max={10000}
                calculate_ci_value={this.calculate_ci_value}
                isMobile={isMobile}
              />

              <CIFooter
                sig={this.state.sig}
                ci_value={ci_value}
                percentage={this.state.percentage}
                openModal={this.openModal}
              />
            </Grid>

            <Grid item xs={12}>
              <CIResult ci_value={ci_value} ci_tooltip={ci_tooltip} />
            </Grid>
          </Grid>
        </Grid>

        <Modal
          isOpen={this.state.examples_modal}
          onRequestClose={this.closeModal}
          closeModal={this.closeModal}
          contentLabel="Examples"
          isMobile={isMobile}
        >
          <ConfExample
            ci_value={ci_value}
            percentage={this.state.percentage}
            sig={this.state.sig}
          />
        </Modal>
      </div>
    );
  }
}

export default ConfidenceInterval;
