import React, { Component } from 'react';
import { Grid, FormControl, MenuItem, Radio, RadioGroup, FormControlLabel, Typography} from '@material-ui/core';
import ReactSelect from 'react-select';

class CustomSelect extends Component {
  option(props) {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        disabled={props.data.disabled}
        style={{
          fontWeight: props.data.selected ? 'bold' : 'normal',
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  render() {
    let options = this.option
    return (
      <ReactSelect
        className={"mt-3 react-select "+this.props.className}
        value={this.props.selected || ''}
        options={this.props.items}
        components={{ Option: options }}
        onChange={(event) => this.props.setVal(event.value)}
        placeholder={this.props.label}
        isSearchable={this.props.searchable}
      />
    );
  };
}

class DownloadArea extends Component {
  render() {
    if(this.props.allowSend) {
      return (
        <div className="d-flex justify-content-center w-100 pt-2 send-icon" onClick={this.props.sendFile}>
        <img
          src="forward.svg"
          className="align-self-end"
          alt="Download" style={{height: "50px"}}/>
        </div>
      )
    } else {
      return (
        <div className="d-flex justify-content-center w-100 pt-2 send-icon-disabled">
          <img
            src="forward.svg"
            className="align-self-end"
            alt="Download" style={{height: "50px"}}/>
        </div>
      )
    }
  }
}

class SourceLang extends Component {
  menuWidth = () => (
    window.innerWidth >= 960 ? "w-100 mx-auto" : "w-75 mx-auto"
  )
  render() {
    //let className = (window.innerWidth > 500) ? ' pl-4' : '';
    return (
      <Grid container
        direction="row"
        className={this.menuWidth()}
      >
        <Grid item
          xs={12}
          md={6}
        >
          <FormControl component="fieldset"
            className="m-0 mt-2 p-0 w-50 formcontrol-cheat"
          >
            <RadioGroup
              aria-label="Source language"
              name="sourceLangType"
              value={this.props.isGlobal ? 'global' : 'per_row'}
              onChange={(event) => this.props.setIsGlobal(event.target.value)}
            >
              <FormControlLabel
                value="global"
                className="radio-label m-0 p-0"
                control={ <Radio className="m-0 p-0"/>}
                label={<Typography variant="subtitle2" style={{left:"-10px"}}>Global</Typography>}/>
              <FormControlLabel
                value="per_row"
                className="radio-label m-0 p-0"
                control={<Radio className="m-0 p-0"/>}
                label={<Typography variant="subtitle2" style={{left:"-10px"}}>Per row</Typography>}/> />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item
          xs={12}
          md={6}
        >
          <CustomSelect
            label={this.props.label}
            selected={this.props.selected}
            items={this.props.items}
            setVal={this.props.setSourceLang}
            searchable={this.props.searchable}
          />
        </Grid>
      </Grid>
    );
  }
}

export { DownloadArea, SourceLang, CustomSelect }
