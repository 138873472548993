import React, { Component } from 'react';
import UploadDragger from './lib/Dragger.js';
import { Typography } from '@material-ui/core'

import './assets/css/sidebar.css';

const InputPanel = ({updateText, rows, text, loadPanel, saveFile, closeTabs}) => (

    <div>
      <div className="row H-100 W-100" id="upload_drag_container">
          <UploadDragger
              updateText={updateText}
              loadPanel={loadPanel}
              saveFile={saveFile}
              closeTabs={closeTabs}
          />
      </div>
      <div className="row">
        <div className="col dropdown-divider"></div>
        <Typography component="div" variant="subtitle2" align="center" className="mr-3 col-1">
          OR
        </Typography>
        <div className="col dropdown-divider"></div>
      </div>
      <Typography component="span" align="center" variant="body1">
        Paste your text below
      </Typography>
      <div className="row mt-2">
        <textarea
          rows={rows}
          className="form-control"
          value={text}
          onChange={(ev) => updateText(ev.target.value)}
          placeholder="Paste text"
        />
      </div>
    </div>
)


class Input extends Component {

  constructor(props){
    super(props);
    this.updateText = this.updateText.bind(this);
    this.state = {
        text: this.props.text,
    }
    this.setTimer = this.setTimer.bind(this);
  }

  updateText(newText){
    this.setState({
      text: newText,
      delay: 1000
    })
    this.setTimer(() => this.props.updateText(this.state.text));
  }

  setTimer(callback){
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      callback();
    }, this.state.delay)
  }

  componentWillReceiveProps(prevProps, prevState, snapshot){
    this.setState({
      text: prevProps.text,
    })
  }

  render() {

    let rows = Math.floor((window.innerHeight - 48)/48) ;

    let panel = <InputPanel
                    text={this.state.text}
                    updateText={this.updateText}
                    loadPanel={this.props.loadPanel}
                    saveFile={this.props.saveFile}
                    closeTabs={this.props.closeTabs}
                    rows={rows}
                />

    return(
      <div className="m-3 mr-0">
        <div className="row border-bottom mb-2 pb-2">
          <Typography component="span" variant="h5" align="center">
            Input
          </Typography>
        </div>
        { panel }
      </div>
    );
  }
}

export default Input;
