import React from 'react';

import {
  withHighcharts,
  HighchartsChart,
  LineSeries,
  SplineSeries,
  Chart,
  XAxis,
  YAxis
} from 'react-jsx-highstock';

import Highcharts from 'highcharts';

class CIGraph extends React.Component {

  /* Component for the Confidence Interval Graph */

  constructor(props){
    super(props);
    this.graph_data = this.graph_data.bind(this);
    this.loaded = this.loaded.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /* Helpers */

  label_x_offset(){

    // Determine offset for series labels
    var x_offset;
    if (this.props.n_perc < 50){
      x_offset = 20;
    } else {
      x_offset = 0;
    }
    return x_offset;

  }

  graph_data(z_value, percentage, n_max, n_min, d=1){

    /* Generate data points for the graph */

    var graph_data = [];
    var num_points = this.props.isMobile ? 49 : 99;
    var ci_value;
    var p = percentage / 100;
    var lower_detected = false;
    var upper_detected = false;
    for (var i = 0; i < num_points; i++){
      var x = ((n_max-n_min)/num_points)*i + n_min;
      ci_value = this.props.calculate_ci_value(z_value, percentage, x);
      if (d === -1) {
          if (percentage - ci_value >= 0) {
              graph_data.push({
                  'x': x,
                  'y': +(this.props.calculate_ci_value(z_value, percentage, x) * 10 * d).toFixed(2)
              });

          } else if (!lower_detected) {
              lower_detected = true;
              x = z_value * z_value * (1 - p) / p;
              graph_data.push({
                  'x': x,
                  'y': +(this.props.calculate_ci_value(z_value, percentage, x) * 10 * d).toFixed(2)
              })
          }
      } else {
          if(percentage + ci_value <= 100){
              graph_data.push(  {
                  'x': x,
                  'y': +(this.props.calculate_ci_value(z_value, percentage, x) * 10 * d).toFixed(2)
              });
        } else if(!upper_detected){
            upper_detected = true;
            x = z_value * z_value * p/ (1-p);
            graph_data.push({
                'x': x,
                'y': +(this.props.calculate_ci_value(z_value, percentage, x) * 10 * d).toFixed(2)
            })
        }
      }
    }

    // Generate the last point (x=n_max) separately (or graph will be truncated)
    graph_data.push({'x': n_max,
        'y': +(this.props.calculate_ci_value(z_value, percentage, n_max)*10*d).toFixed(2)})

    return graph_data;

  }

  loaded(){
      //`console.log`('hey')
  }

  handleChange() {
    if ('onorientationchange' in window) {
         window.addEventListener("orientationchange", function(){}, false);
    }
  }

  componentDidMount(){
    this.handleChange();
  }

  render () {

    /* Renders the CI Graph */

    var scale;
    var cursor_width;
    var x_value;
    var x_labels;
    var ci_value_apparent_top;
    var ci_value_apparent_bottom;

    const a = 772;
    const b = 2225;

    // Set scale based on size of n

    if (this.props.n <= a){
      scale = {'min': 30, 'max': 1000};
      cursor_width = 20;
    } else if (this.props.n <= b) {
      scale = {'min': 300, 'max': 3000};
      cursor_width = 60;
    } else {
      scale = {'min': 1000, 'max': 10000};
      cursor_width = 200;
    }

    // Hide scale and stop resizing curor if n > max

    if (this.props.n > this.props.max){
      x_value = this.props.max;
      x_labels = {
        'enabled': 'true',
        'style': {
          'color': '#f7f6f2'
        }
      }
    } else {
      x_value = this.props.n;

      let p = this.props.percentage/100;


       var x = this.props.z_value * this.props.z_value * p  / (1 - p);
       ci_value_apparent_top = (this.props.percentage + this.props.ci) <= 100 ? this.props.ci : +(this.props.calculate_ci_value(this.props.z_value, this.props.percentage, x) ).toFixed(2);
       //ci_value_apparent_top = this.props.ci

        x = this.props.z_value * this.props.z_value * (1 - p) / p;
       ci_value_apparent_bottom = (this.props.percentage - this.props.ci) >= 0 ? this.props.ci : +(this.props.calculate_ci_value(this.props.z_value, this.props.percentage, x) ).toFixed(2);


      x_labels = {
        'enabled': 'true',
        'style': {
          'color': '#898988'
        }
      }
    }

    // Caclulate positions of cursor (represented as three data series)

    var ci_cursor=[{x: x_value, y: ci_value_apparent_top*10}, {x: x_value, y: ci_value_apparent_bottom*-10}]
    var ci_cursor_top=[{x: x_value-cursor_width/2, y: ci_value_apparent_top*10}, {x: x_value+cursor_width/2, y: ci_value_apparent_top*10}]
    var ci_cursor_bottom=[{x: x_value-cursor_width/2, y: ci_value_apparent_bottom*-10}, {x: x_value+cursor_width/2, y: ci_value_apparent_bottom*-10}]

    // Generate CI value data series for graph

    var upper_graph_data = this.graph_data(this.props.z_value, this.props.percentage, scale['max'], scale['min'], 1)
    var lower_graph_data = this.graph_data(this.props.z_value, this.props.percentage, scale['max'], scale['min'], -1)
    var upper_straight_data = [{x: scale['min'], y: upper_graph_data[0].y}, {x: upper_graph_data[0].x, y: upper_graph_data[0].y}];
    var lower_straight_data = [{x: scale['min'], y: lower_graph_data[0].y}, {x: lower_graph_data[0].x, y: lower_graph_data[0].y}];
    //var ci_cursor=[{x: x_value, y: upper_graph_data[0].y}, {x: x_value, y:lower_graph_data[0].y}]
    //lower_graph_data.map(xy=> xy.y= xy.y < -60 ? -60 : xy.y)

    // Calculate the range min and max values
    var range_max;
    if ((this.props.percentage + this.props.ci) > 100){
        range_max = '100%';
    } else {
        range_max = (this.props.percentage + this.props.ci).toFixed(1) + '%'
    }

    var range_min;
    if ((this.props.percentage - this.props.ci) < 0){
        range_min = '0%';
    } else {
        range_min = (this.props.percentage - this.props.ci).toFixed(1) + '%'
    }

    // Set general options for graph render

    const plotOptions = {
        series: {
      		enableMouseTracking: false,
      		animation: false,
      		marker: {
      			states: {
      				hover: {
      					enabled: false
      				}
      			}
      		},
      	},
      	area: {
      		softThreshold: true,
      	},
      	spline: {
      		animation: false,
      	},
      	line: {
      		animation: false,
      	}
    }

    // Define label CSS

    var label_style = {
      'fontSize': '17px',
      'textOutline': false,
      'fontFamily': 'Arial',
      'color': '#0f41d7',
    }

    var ci_axis_label_style =  {
      'textOutline': false,
      'fontFamily': 'Arial',
      'color': '#00abff',
      'background': '#f7f6f2',
      'border': '1px solid #79d2e5'
    }

    var margin_top =30;
    var self = this;

    return (

      /* Render the CI Graph
      */
      <div style={{ width:'100%'}}>

        <HighchartsChart plotOptions={plotOptions}>

          <Chart
            animation={false}
            backgroundColor='#FFFFFF'
            marginTop={margin_top}
            ref='chart'
          />

          <XAxis
            gridLineWidth={0}
            lineWidth={0}
            minorTickLength={0}
            tickLength={0}
            min={scale['min'] - 5}
            max={scale['max'] + 5}
            labels={x_labels}
          />

          <YAxis id="number" gridLineWidth={0} min={-205} max={205} labels='None'>

            <LineSeries
              id="x_axis"
              data={[{x: scale['min'], y: 0, dataLabels:{
                'enabled': true,
                'useHTML':true,
                'formatter': function() {
                   return '<div id="ci_axis_label" className="light_blue">'+self.props.percentage+'%</div>'
                 },
                 'style': ci_axis_label_style,

              }}, {x: scale['max'], y: 0}]}
              color='#00abff'
              lineWidth={3}
              marker='None'
            />

            <LineSeries
              id="cursor"
              data={ci_cursor}
              color='#0f41d7'
              lineWidth={4}
            />

            <LineSeries
              id="cursor_top"
              data={ci_cursor_top}
              color='#0f41d7'
              lineWidth={4}
              marker='None'
              dataLabels={{
                'enabled': true,
                'formatter': function() {
                   return 'upper value: ' + range_max;
                 },
                 'style': label_style,
                 'y': -5,
                 'x': this.label_x_offset() - 10,
                 'align': 'left'
              }}
            />

            <LineSeries
              id="cursor_bottom"
              data={ci_cursor_bottom}
              color='#0f41d7'
              lineWidth={4}
              marker='None'
              dataLabels={{
                'enabled': true,
                'formatter': function() {
                   return 'lower value: ' + range_min;
                 },
                 'style': label_style,
                 'y': 30,
                 'x': this.label_x_offset() - 10,
                 'align': 'left'
              }}
            />

            <LineSeries
              id="upper_straight"
              data={upper_straight_data}
              color='#000028'
              lineWidth={2}
              marker='None'
            />

            <SplineSeries
              id="ci_upper"
              name="ci_upper"
              data={upper_graph_data}
              color='#0f41d7'
              marker='None'
            />

            <LineSeries
              id="lower_straight"
              data={lower_straight_data}
              color='#0f41d7'
              lineWidth={2}
              marker='None'
            />

            <SplineSeries
              id="ci_lower"
              name="ci_lower"
              data={lower_graph_data}
              color='#0f41d7'
              marker='None'
            />

          </YAxis>
        </HighchartsChart>
      </div>

    )}

}

export default withHighcharts(CIGraph, Highcharts);
