import React, { Component } from "react";
import { Grid } from "@material-ui/core";

import HomeCard from "./lib/HomeCard.js";
import APP_CONFIG from "./etc/apps.js";

class Home extends Component {
  constructor() {
    super()
    const isIE = !!document.documentMode;

    const isErrorPage = window.location.href.includes("/error.html")

    if ( isIE && (!isErrorPage) ) {
      console.log("redirecting to error")
      window.location.href = process.env.PUBLIC_URL + "error.html"
    }
  }


  componentDidMount() {
    const redirectUri = localStorage.getItem("redirect");
    if (redirectUri) {
      localStorage.removeItem("redirect");
      if (redirectUri !== "/login") window.location = redirectUri;
    }
  }

  render() {
    const {
      group,
      auth: { isAuthenticated }
    } = this.props;
    const { main, ...APP_GROUPS } = APP_CONFIG;
    const LOGGED_IN = isAuthenticated();

    return (
      <div className="h-100 w-100 mx-auto flex-1 d-flex">
        <Grid item container className="home-apps flex-1 pt-3" justify="center">
          {/* Render cards for apps in this group */
          group.apps.map(app => {
            return (
              app.active &&
              (app.public || (!app.public && LOGGED_IN)) && (
                <Grid item xs={12} sm={5} md={4} lg={3} className="m-3">
                  <HomeCard
                    href={app.path}
                    src={app.icon}
                    title={app.name}
                    description={app.short_description}
                  />
                </Grid>
              )
            );
          })}
          {/* If this is the home page, also render cards for app groups */
          group.path === "/" &&
            Object.keys(APP_GROUPS).map(key => {
              const app_group = APP_GROUPS[key];
              return (
                app_group.active &&
                (app_group.public || (!app_group.public && LOGGED_IN)) && (
                  <Grid item xs={12} sm={5} md={4} lg={3} className="m-3">
                    <HomeCard
                      href={app_group.path}
                      src={app_group.icon}
                      title={app_group.name}
                      description={app_group.short_description}
                    />
                  </Grid>
                )
              );
            })}
        </Grid>
      </div>
    );
  }
}

export default Home;
