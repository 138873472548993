const copyDict = obj => {
    var sliced = {};
    for (var k in obj) {
        sliced[k] = obj[k];
    }
    return sliced;
}

const randomBetween = (min, max) => {
   return Math.floor(Math.random() * max) + min
}

const pickRandom = (items) => {
   return items[Math.floor(Math.random()*items.length)]
}

const hslToHex = (h, s, l) => {
      let r, g, b;
      h = parseInt(h)
      s = ( h === -1 ) ? 0 : parseInt(s)/100;
      h = h/360;
      l = parseInt(l)/100;

      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);

      const toHex = x => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      };
      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

const wrapURL = (url) => {
  // add JWT tokens to a URL
  let access_token = localStorage.getItem('access_token');
  let id_token = localStorage.getItem('id_token');
  url += '?id_token=' + id_token + '&access_token=' + access_token
  return url
}

export {copyDict, randomBetween, pickRandom, hslToHex, wrapURL}
