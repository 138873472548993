import React from 'react'

import { Typography } from '@material-ui/core';

import {
    MuiThemeProvider,
    createMuiTheme,
    CssBaseline } from '@material-ui/core';

import Favicon from 'react-favicon';
import favicon from '../assets/img/favicon.ico';


class NotFound extends React.Component {
  render(){
    return (
      <div className="login-container">
        <Favicon url={favicon} />
        <MuiThemeProvider theme={theme}>
        <Favicon url={favicon} />
        <CssBaseline />
        <Typography component="span" variant="h2" color="textSecondary">404 ;(</Typography>
        <Typography component="span" variant="subtitle1" color="textSecondary">
          The page you were looking for could not be found.
        </Typography>
        </MuiThemeProvider>
      </div>
    )
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiRadio: {
      colorSecondary: {
        color: '#0f23a4 !important',
      },
    },
  },
  typography: {
    fontFamily: ['Factworks'],
    h2: { // tool title
      marginTop: '20px',
      marginBottom: '10px',
      fontSize: '40px',
      lineHeight: '48px',
      fontWeight: '700',
    },
    subtitle1: { // tool description
      fontSize: '22px',
      lineHeight: '32px',
    },
    h3: { // home card title
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: '700',
    },
    subtitle2: { // home card description
      fontSize: '16px',
      lineHeight: '22px',
    },
    body1: { // stats calculator
      lineHeight: '28px',
      fontSize: '18px',

    }
  },
  palette:{
    text: {
      primary: '#000028',
      secondary: '#fff'
    },
    primary: {
      main: "#00abff",
    },
    secondary: {
      main: "#000028",
    },
    background: {
      default: '#0f23a4'
    },
  },
  shadows: ['none'],
});

export default NotFound
