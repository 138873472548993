import React, { Component } from 'react';
import Settings             from './Settings.js';
import WordList             from './WordList.js';
import StopwordList         from './StopwordList'
import Input                from './Input.js';
import { Grid }             from '@material-ui/core';
import MediaQuery           from 'react-responsive';
import input                from './assets/img/input.svg'
import wordlist             from './assets/img/wordlist.svg'
import setting              from './assets/img/cog.svg'
import stopwords            from './assets/img/stopwords.svg'

import 'rc-drawer/assets/index.css';


class SideTabs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      whichOpen: 'none',
    }
    this.handleClick     = this.handleClick.bind(this);
    this.renderTab       = this.renderTab.bind(this);
    this.handleMaskClick = this.handleMaskClick.bind(this);
    this.setWrapperRef   = this.setWrapperRef.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMaskClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMaskClick);
  }

  handleMaskClick(event) {
    // if wrapper exists (i.e. side tabs are open) and wrapper doesn't contain event clicked
    let wrapperConditions = (event === undefined) ? true : (this.wrapperRef && !this.wrapperRef.contains(event.target))
    // if sidetabs are not fixed, menu item clicks don't register as being part of wrapperRef. workaround is to filter classnames
    let classNameConditions = (event === undefined) ? true : !((typeof event.target.className === 'string') && event.target.className.includes('unmask'))

    if (wrapperConditions && classNameConditions) {
      this.setState({ whichOpen: 'none' })
    }
  }

  handleClick(tabName) {
    const whichOpen = this.state.whichOpen;
    var newOpen = (tabName === whichOpen) ? 'none' : tabName;
    this.setState({ whichOpen: newOpen });
  }

  renderTab(whichOpen) {
    const allWords  = this.props.wordList

    const stopword_objects =  allWords.filter( d => d.stopword)
    const non_stopword_objects = allWords.filter( d => !d.stopword)

    const settings = <Settings
      settings={this.props.settings}
      fonts={this.props.fonts}
      colorSchemes={this.props.colorSchemes}
      updateSetting={this.props.updateSetting}
      cloudType={this.props.cloudType}
      unsetOrientation={this.props.unsetOrientation}
      isLoggedIn={this.props.isLoggedIn}
    />

    const wordlist = <WordList
      wordList={non_stopword_objects}
      displayList={this.props.displayList}
      updateWordList={this.props.updateWordList}
      updateExcludedWords={this.props.updateExcludedWords}
      setWordlist={this.props.setWordlist}
      handleMerge={this.props.handleMerge}
      handleUnmerge={this.props.handleUnmerge}
    />;

    const input = <Input
      text={this.props.text}
      updateText={this.props.updateText}
      saveFile={this.props.saveFile}
      closeTabs={this.handleMaskClick}
    />;

    const stopwordList = <StopwordList
      wordList={stopword_objects}
      displayList={this.props.displayList}
      updateWordList={this.props.updateWordList}
      updateExcludedWords={this.props.updateExcludedWords}
      setWordlist={this.props.setWordlist}
    />
    switch(whichOpen) {
      case 'settings':
        return settings;
      case 'wordlist':
        return wordlist;
      case 'input':
        return input;
      case 'stopwordlist':
        return stopwordList;
      default:
        break;
    }
  }

  render() {
    let whichOpen           = this.state.whichOpen;
    let handlesOpen         = '';
    if (window.innerWidth >= 1300) {
       whichOpen = whichOpen === 'none' ? 'input' : whichOpen ;
    } else if (whichOpen !== 'none') {
      handlesOpen = ' handles-open';
    }

    let display         = whichOpen === 'none' ? 'none' : 'block';
    let tabStyle        = "handles p-3 border";
    let selectedStyle   = tabStyle + " border-left-0";
    let unselectedStyle = tabStyle + " bg-light";
    let handlesInner    = 'handles-inner'+handlesOpen;

    const handle = (
      <div className={handlesInner}>
        <div className={ whichOpen === 'input' ? selectedStyle : unselectedStyle }
          onClick={() => this.handleClick('input')}
        >
          <img src={input} alt="Input"/>
        </div>

        <div className={ whichOpen === 'settings' ? selectedStyle : unselectedStyle }
          onClick={() => this.handleClick('settings')}
        >
          <img src={setting} alt="Settings"/>
        </div>

        <div className={ whichOpen === 'wordlist' ? selectedStyle : unselectedStyle }
          onClick={() => this.handleClick('wordlist')}
        >
          <img src={wordlist} alt="Wordlist editor"/>
        </div>
        <div className={ whichOpen === 'stopwordlist' ? selectedStyle : unselectedStyle }
          onClick={() => this.handleClick('stopwordlist')}
        >
          <img src={stopwords} alt="Stopword editor"/>
        </div>
      </div>
    );

    return (
      <>
        <MediaQuery minWidth={1300}>
          {(matches) => {
            if (matches) {
              return (
                <Grid item container direction="row">
                <Grid item container className="h-100">
                  <div className="p-3 my-3 rounded wc-side-tabs w-100">
                    {this.renderTab(whichOpen)}
                  </div>
                </Grid>
                <div className="m-0 p-0">
                  <div className="handles-outer">
                    {handle}
                  </div>
                </div>
              </Grid>)
            }
            else {
              return (
                <div ref={this.setWrapperRef}>
                  <div className="border rounded wc-side-tabs p-3"
                    style={{display: display}}
                  >
                    <div className="m-0 p-0 overflow-auto">
                      { this.renderTab(whichOpen) }
                    </div>
                  </div>
                  <div className="m-0 p-0">
                    <div className="handles-outer collapsed">
                      {handle}
                    </div>
                  </div>
              </div>)
            }
          }}
        </MediaQuery>
      </>
    );
  }
}

export default SideTabs;
