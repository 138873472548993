import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";

import MediaQuery from "react-responsive";
import APP_CONFIG from "../etc/apps.js";
import BurgerMenu from "./Burger.js";

import logo_white from "../assets/img/logo-white.svg";
import logo_full_white from "../assets/img/logo-full-white.svg";

const LogoutButton = props => {
  const { isAuthenticated } = props.auth;

  const ACTION = isAuthenticated() ? "Logout" : "Login";
  const HANDLER = isAuthenticated() ? props.auth.logout : props.auth.login;

  return (
    <button onClick={HANDLER} className="logout-button clean-button p-0">
      <Typography
        component="span"
        className="header-closed-app"
        variant="button"
        color="textSecondary"
      >
        <b>{ACTION.toUpperCase()}</b>
      </Typography>
    </button>
  );
};

class MenuBar extends Component {
  makeAllLinks(APP_CONFIG, LOGGED_IN, currentApp) {
    const { main, ...APP_GROUPS } = APP_CONFIG;

    let isToolboxOpen = currentApp === "Factworks Toolbox";
    const toolboxClassName = isToolboxOpen
      ? "header-open-app"
      : "header-closed-app";
    const toolboxLink = this.makeLink("/", "toolbox", toolboxClassName);

    const mainLinks = main.apps.map(app => {
      if ((LOGGED_IN || app.public) && app.active) {
        let className =
          app.name === currentApp ? "header-open-app" : "header-closed-app";
        return this.makeLink(app.path, app.header_name, className);
      }
      return "";
    });

    const groupLinks = Object.keys(APP_GROUPS).map((key, index) => {
      let group = APP_GROUPS[key];
      if ((LOGGED_IN || group.public) && group.active) {
        let className =
          group.name === currentApp ||
          group.apps.filter(app => app.name === currentApp).length > 0
            ? "header-open-app"
            : "header-closed-app";
        return this.makeLink(group.path, group.header_name, className);
      }
      return "";
    });

    return [toolboxLink].concat(mainLinks).concat(groupLinks);
  }

  makeLink(path, name, className) {
    return (
      <a href={path} className="clean-button">
        <Typography
          component="span"
          variant="button"
          color="textSecondary"
          className={className}
        >
          <b>{name.toUpperCase()}</b>
        </Typography>
      </a>
    );
  }

  getTitleDescription(APP_CONFIG, currentApp) {
    const appType = "apps" in currentApp ? "group" : "app";

    let title;
    let description;

    if (
      appType === "group" ||
      (appType === "app" && currentApp.group === "main")
    ) {
      title = currentApp.name;
      description = currentApp.short_description;
    } else {
      title = APP_CONFIG[currentApp.group].title;
      description = APP_CONFIG[currentApp.group].description;
    }

    return [title, description];
  }

  render() {
    const LOGGED_IN = this.props.auth.isAuthenticated();

    const links = this.makeAllLinks(APP_CONFIG, LOGGED_IN, this.props.name);
    const logoutButton = <LogoutButton auth={this.props.auth} />;

    let [title, description] = this.getTitleDescription(
      APP_CONFIG,
      this.props.app
    );

    let titleElement = (
      <Grid item xs={12} align="center" className="pt-4 p-1 home-title">
        <div>
          <Typography component="span" variant="h2" color="textSecondary">
            {title}
          </Typography>
          <Grid item xs={11} md={6} align="center">
            <Typography
              component="span"
              variant="subtitle1"
              color="textSecondary"
            >
              {description}
            </Typography>
          </Grid>
        </div>
      </Grid>
    );

    let smallHeader = (
      <Grid container className="menu-bar p-3" justify="space-between">
        <Grid item>
          <a href="http://www.factworks.com">
            <img
              src={logo_white}
              alt="Factworks"
              className="fw-logo clean-button"
            />
          </a>
        </Grid>
        <BurgerMenu links={links} logout={logoutButton} />
        {titleElement}
      </Grid>
    );

    let normalHeader = (
      <Grid
        container
        className="menu-bar p-3"
        direction="row"
        justify="space-between"
      >
        <Grid item>
          <a href="http://www.factworks.com">
            <img src={logo_full_white} alt="Factworks" className="fw-logo" />
          </a>
        </Grid>

        <Grid item className="mb-2">
          <Grid container alignItems="flex-end" spacing={16}>
            {links.map((l, index) => {
              if (l) {
                return (
                  <Grid item className="mt-4" key={index}>
                    <Grid container>{l}</Grid>
                  </Grid>
                );
              } else {
                return "";
              }
            })}
            <Grid item className="mt-4 mr-4" key="logout">
              <Grid container>{logoutButton}</Grid>
            </Grid>
          </Grid>
        </Grid>
        {titleElement}
      </Grid>
    );

    return (
      <MediaQuery maxWidth={720}>
        {matches => {
          if (matches) {
            return smallHeader;
          } else {
            return normalHeader;
          }
        }}
      </MediaQuery>
    );
  }
}

export default MenuBar;
