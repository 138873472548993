import React from 'react';

import API from './lib/api';

class Download extends React.Component {

  constructor(props){
    super(props);
    this.API = new API();
    this.downloadFile = this.API.downloadFile.bind(this);
  }

  render(){
    const { isAuthenticated } = this.props.auth;
    let message = "Access denied."
    if (isAuthenticated()){
      this.downloadFile(this.props.match.params.job_id);
      message = "Downloading file " + this.props.match.params.job_id
    }
    return (
        <p>
        {message}
        </p>
    )
  }

}

export default Download;
