import React from "react";

import jStat from "jstat";
import RSlider from "./lib/Slider.js";
import SubTitle from "./lib/SubTitle.js";
import PropResults from "./lib/PropResults.js";
import ResultsExplanation from "./lib/ResultsExplanation.js";
import { PropIndExample } from "./lib/Example.js";

import Modal from "../lib/Modal.js";
import { Grid, Typography } from "@material-ui/core";
import SubMenu from "./lib/SubMenu.js";

class ProportionTestInd extends React.Component {
  /* Main component for the Independent Proportion Test */

  constructor(props) {
    super(props);
    document.body.style.overflow = "auto";
    this.state = {
      prop_a: 90.5,
      prop_b: 95.7,
      prop_na: 600,
      prop_nb: 800,
      sig: 95,
      a_max: 5000,
      b_max: 5000,
      examples_modal: false
    };
    this.handler_prop_a = this.handler_prop_a.bind(this);
    this.handler_prop_b = this.handler_prop_b.bind(this);
    this.handler_na = this.handler_na.bind(this);
    this.handler_nb = this.handler_nb.bind(this);
    this.handler_sig = this.handler_sig.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  /* Handlers - update state based on user inputs */

  openModal() {
    this.setState({ examples_modal: true });
  }

  closeModal() {
    this.setState({ examples_modal: false });
  }

  handler_prop_a(input_value) {
    this.setState({
      prop_a: input_value
    });
  }

  handler_prop_b(input_value) {
    this.setState({
      prop_b: input_value
    });
  }

  handler_na(input_value) {
    this.setState({
      prop_na: input_value,
      na: input_value
    });
  }

  handler_nb(input_value) {
    this.setState({
      nb: input_value,
      prop_nb: input_value
    });
  }

  handler_sig(input_value) {
    this.setState({
      sig: +input_value.toFixed(0)
    });
  }

  render() {
    /* Render the component */

    const isMobile = window.innerWidth <= 850;

    var z_value;

    if (this.state.prop_a - this.state.prop_b === 0) {
      z_value = 0;
    } else {
      const a_perc = +(this.state.prop_a / 100).toFixed(4);
      const b_perc = +(this.state.prop_b / 100).toFixed(4);
      z_value = (
        (a_perc - b_perc) /
        Math.sqrt(
          (a_perc * (1 - a_perc)) / this.state.prop_na +
            (b_perc * (1 - b_perc)) / this.state.prop_nb
        )
      ).toFixed(3);
    }

    // Calculate the expected p and z values for comparison

    var p_value = jStat.ztest(z_value, 2).toFixed(3);
    var expected_z_value = Math.abs(
      jStat.normal.inv(0.5 - this.state.sig / 100 / 2, 0, 1).toFixed(3)
    );

    const SUBTITLE =
      "This calculator is used to compare observed proportions \n from two independent groups to determine if they are significantly \n different from one another.";

    return (
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Grid container direction="row" spacing={16}>
          <Grid item xs={12}>
            <SubMenu currentApp="ind" />
          </Grid>

          <Grid
            item
            container
            spacing={16}
            className="calc-container mx-auto rounded p-4 mb-5"
          >
            <Grid item xs={12}>
              <SubTitle text={SUBTITLE} />
            </Grid>

            <Grid item sm={6} xs={12}>
              <div id="prop-ind-a" className="rounded px-3">
                <RSlider
                  value={this.state.prop_a}
                  label="Group A"
                  label_mobile="A"
                  size={6}
                  colour="blue"
                  percentage={true}
                  handler={this.handler_prop_a}
                  id="prop-ind-a-slider"
                  tip={false}
                  min={0}
                  max={100}
                  tooltip={isMobile ? "Group A" : ""}
                />
                <RSlider
                  value={this.state.prop_na}
                  label="Sample Size"
                  label_mobile="N"
                  colour="blue"
                  min={1}
                  size={6}
                  max={this.state.a_max}
                  id="prop-ind-na-slider"
                  handler={this.handler_na}
                  tip={true}
                  decimals={false}
                  tooltip={isMobile ? "Sample Size" : ""}
                />
              </div>
            </Grid>

            <Grid item sm={6} xs={12}>
              <div id="prop-ind-b" className="rounded px-3">
                <RSlider
                  value={this.state.prop_b}
                  label="Group B"
                  label_mobile="B"
                  size={6}
                  colour="cyan"
                  percentage={true}
                  id="prop-ind-b-slider"
                  handler={this.handler_prop_b}
                  tip={false}
                  min={0}
                  max={100}
                  tooltip={isMobile ? "Group B" : ""}
                />
                <RSlider
                  value={this.state.prop_nb}
                  label="Sample Size"
                  label_mobile="N"
                  colour="cyan"
                  size={6}
                  min={1}
                  max={this.state.b_max}
                  id="prop-ind-nb-slider"
                  handler={this.handler_nb}
                  tip={true}
                  tooltip={isMobile ? "Sample Size" : ""}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <RSlider
                value={this.state.sig}
                label="Significance Level"
                label_mobile="Sig"
                min={80}
                max={99}
                size={12}
                colour="grey"
                percentage={true}
                sig="true"
                id="prop-ind-sig-slider"
                handler={this.handler_sig}
                tip={false}
                decimals={false}
                tooltip={
                  isMobile
                    ? "Significance Level"
                    : "<big>The level of confidence that the Confidence Interval will contain the true population proportion.</big>"
                }
              />
            </Grid>

            <Grid id="sig-results-box" container spacing={24}>
              <Grid item xs={12} sm={6}>
                <PropResults
                  z_value={z_value}
                  p_value={p_value}
                  sig={this.state.sig}
                  type="ind"
                  expected_z_value={expected_z_value}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <ResultsExplanation type="dep" />
                <Typography className="pt-3">
                  Need help? See{" "}
                  <b
                    onClick={this.openModal}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    examples
                  </b>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          isOpen={this.state.examples_modal}
          closeModal={this.closeModal}
          contentLabel="Examples"
          isMobile={isMobile}
        >
          <PropIndExample
            sig={this.state.sig}
            prop_a={this.state.prop_a}
            prop_b={this.state.prop_b}
          />
        </Modal>
      </div>
    );
  }
}

export default ProportionTestInd;
