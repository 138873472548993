
import React from 'react';

class Tip extends React.Component {

  render(){

    var text;
    var tip_container_class = "tip_container";

    if (this.props.slider_size === "xlarge"){
        tip_container_class = "tip_container_xlarge";
    } else {
        tip_container_class = "tip_container";
    }

    if (this.props.isMobile){
        tip_container_class = "tip_container";
    }

    if (this.props.type === 'enter_larger'){
        text = 'You can enter a larger N value';
    } else if (this.props.type === 'enter_or_slide') {
        text = 'Enter a value or slide';
    }

    return (
      <div className={tip_container_class}>
        <span className="slider_info_text">
          <p>{ text }</p>
        </span>
      </div>
    )
  }

}

export default Tip;
