import React from 'react';
import { Typography } from '@material-ui/core';

const HomeCard = (props) => (
    <a href={props.href}>
      <div className="home-card rounded h-100" >
        <div className="hc-overlay rounded w-100 h-100 m-0 p-0"> </div>
        <div className="clickable p-4">
          <div className="row m-0">
            <img src={props.src} alt={props.title} className="home-icon-sizing mb-2"/>
          </div>
          <Typography component="div" variant="h3" gutterBottom={true}>
            {props.title}
          </Typography>
          <Typography component="div" variant="subtitle2">
            {props.description}
          </Typography>
        </div>
      </div>
    </a>
)

export default HomeCard;
