import React, { Component } from 'react';
import { hslToHex } from '../../lib/Helpers.js'

const generateLine = (line, maxFreq, font, hue, delta, offset) => {
    return line.map((word, index) => {
	    let hex = hslToHex(hue, 50, ((1.40 - word.value / maxFreq) * 60))
	    let size = (word.value/maxFreq)*3*delta + 1.2
      let spacing = index === 0 ? 30 : '0.4em'
      return (
	        <tspan key={index}>
	         <tspan
	          key={word.word}
	          id={word.value}
	          className="wc-linear-word"
	          fill={hex}
	          fontFamily={font}
	          fontSize={size.toString() + 'em'}
            dx={spacing}
	        >{word.word}
	        </tspan>
          <tspan
             className="wc-linear-word"
             style={{fontSize:'10pt', color:'gray'}}>
             ({word.value})
          </tspan>
	        </tspan>
	    )
    })
}

const estimateWordWidth = (word, maxFreq, delta, font) => {
  let size =  (word.value/maxFreq)*3*delta + 1.2 // font size in em

  let [width, height] = renderHiddenWord(size+'em', font, word.word.toString()); // width of word
  let [mwidth, mheight] = renderHiddenWord(size+'em', font, 'M'); // width of 1em
  let freq = '('+word.value+')'
  let [freqWidth, freqHeight] = renderHiddenWord('10pt', "Factworks", freq) // width of freq display

  return [(width + freqWidth + mwidth*0.4), height+5]
}
const renderHiddenWord = (size, font, word) => {
  let span = document.createElement('span');
  span.append(document.createTextNode(word));
  span.style = "height:auto;width:auto;display:inline-block;visibility:visible;font-family:"+font+";font-size:"+size+";font-weight:initial";
  document.body.append(span);
  let wordWidth = span.offsetWidth;
  let wordHeight = span.offsetHeight;
  span.remove();
  return [wordWidth, wordHeight];
}

class LinearCloud extends Component {
  constructor(props) {
      super(props);
      this.state = {
          data: this.props.data
      }
  }
  render() {
    const { color, data, font, width, height } = this.props;
    let maxFreq = data.length > 0 ? data[0].value : null;

    if(maxFreq) {
      let delta = width/1200
      var lines = [];
      var index = 0;
      var count = 0;
      let forceStop = false;
      let heightBreak = false;
      let currentHeight = 0

      while ((!heightBreak) && !forceStop) {
        let line = [];
        let estLineWidth = 30
        let maxHeight = 0;
        let nextWidth;
        do {
          let word = data[index];
          let [wordWidth, wordHeight] = estimateWordWidth(word, maxFreq, delta, font);
          nextWidth = estLineWidth + wordWidth;
          maxHeight = wordHeight > maxHeight ? wordHeight : maxHeight;

          if(nextWidth < (width-50)) {
            estLineWidth = nextWidth;
            line.push(word)
            index++;
            forceStop = (index >= data.length) // things went wrong if this is true
          } else {
            heightBreak = (currentHeight + maxHeight) >= height
            if(!heightBreak) {
              currentHeight += maxHeight
              lines.push({line: line, height: maxHeight});
            }
          }
        } while ((nextWidth < (width-50)) && (!heightBreak) && !forceStop)
      }

    const text = lines.map((line, index) => {
      return (
    		<tspan x={0} dy={line.height} key={index}>
    		    { generateLine(line.line, maxFreq, font, color, delta) }
    		</tspan>
	    )
    })
      return (
        <svg width={width} height={height} overflow="initial">
          <g>
            <text x="0" y="0">
          	  { text }
            </text>
          </g>
        </svg>
      )
    } else {
      return ""
    }
  }
}

export default LinearCloud;
