import 'core-js/stable/object';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { makeMainRoutes } from './routes';

import "antd/dist/antd.css";
import './assets/css/Bootstrap.scss';
import './assets/css/fonts.css';
import './assets/css/App.css';


const routes = makeMainRoutes();

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
