import React, { Component } from 'react';
import loading from '../assets/loading.svg';

import favicon from '../assets/img/favicon.ico';
import Favicon from 'react-favicon';

class Callback extends Component {
  render() {

    return (
      <div id="callback-container">
        <Favicon url={favicon} />
        <img id="callback-wheel" src={loading} alt="loading"/>
      </div>
    );

  }
}

export default Callback;
