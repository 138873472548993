import React from 'react';
import WordList from './WordList'

const { Toolbar } = require('react-data-grid-addons');

const columns = [
  {
    key: 'unmerge',
    name: ' ',
    editable: false,
    sortable: true,
    width: 40,
  },
  {
    key: 'word',
    name: 'Word',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    key: 'freq',
    name: 'Freq.',
    editable: false,
    sortable: true,
  },
];

class StopwordList extends WordList {
  constructor(props) {
    super(props)

    this.title = "Stopword List"

    this.toolbar = <Toolbar
      addRowButtonText="Hide/Show"
      onAddRow={this.hideMultipleRows}
    />
  }

  componentDidMount() {
    this.openGrid.onToggleFilter()
    this.setState({ columns: columns })
  }


}

export default StopwordList;
