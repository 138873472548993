import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { Grid as TableGrid, Table, TableHeaderRow, TableColumnVisibility } from '@devexpress/dx-react-grid-material-ui';
import MediaQuery from 'react-responsive';

class Chevron extends Component {
  constructor(props) {
    super(props);
    let baseClass  = "t-chevron";
    this.direction = props.direction
    this.className = this.direction === 'right' ? baseClass : baseClass+" t-chevron-left";
  }

  render() {
    if (this.props.disabled) {
      return '';
    } else {
      const src            = (this.props.hidden === 0) ? "chevron-right.svg" :  "chevron-selected.svg";
      const indicatorClass = "mt-auto mb-auto hidden-indicator hidden-indicator-"+(this.direction)
      const displayHidden  = (this.props.hidden === 0) ? '' : (
        <span className={indicatorClass}>
          <svg className="circle-indicator" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <text fontWeight="bold" fill="#00abff" textAnchor="middle" x="0" y="20"   >{this.props.hidden}</text>
            <circle cx="0" cy="15" r="10" stroke="#00abff" strokeWidth="2" fill="none" />
          </svg>
        </span>
      );

      return (
        <MediaQuery minWidth={1000}>
          {(matches) => {
            if(matches) {
              return (
                <Grid item container
                  className="t-outer-chevron h-100 w-100"
                  onClick={() => this.props.handleClick(this.direction)}
                  alignItems="center"
                  >
                    <Grid item>
                      <div style={{position: 'relative'}}>{displayHidden}</div>
                      <img src={src} alt="Pan left" className={this.className}/>
                    </Grid>
                </Grid>
              );
            } else {
              return (
                <div className="d-flex w-100 h-100 mt-3"
                  onClick={() => this.props.handleClick(this.direction)}>
                  <div className="mx-auto">
                    <div style={{position: 'relative'}}>{displayHidden}</div>
                    <img src={src} alt="Pan right" className={this.className}/>
                  </div>
                </div>
              );
            }
          }}
        </MediaQuery>
      )
    }
  }
}

class PreviewArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step:1,
      displayStart: 0,
      displaySize: 9,
    }
    this.handleChevron = this.handleChevron.bind(this);
    this.handleSelect  = this.handleSelect.bind(this);
    this.getDisplaySize = this.getDisplaySize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.getDisplaySize);
    this.getDisplaySize();
  }

  getDisplaySize() {
    let width = window.innerWidth;

    let size  = Math.floor((width - 300)/120);
    size = size < 2 ? 2 : size;
    size = size > 9 ? 9 : size;

    this.setState({ displaySize: size })
  }

  handleChevron(direction) {
    var vis    = this.state.displayStart;
    const size = this.state.displaySize
    const step = this.state.step;
    const amtCols = this.props.columnNames.length;

    switch(direction) {
      case 'right':
        if((vis+size) < amtCols) {
          vis += step;
        }
        break;
      case 'left':
        if(vis > 0) {
          vis -= step
        }
        break;
      default:
        console.log("what?")
        break;
    }
    this.setState({displayStart: vis});
  }

  handleSelect(selected) {
    const colNames      = this.props.columnNames;
    const trueIndex     = colNames.indexOf(selected);

    this.props.selectCol(trueIndex);
  }

  render() {
    let amtCols       = this.props.columnNames.length;
    let selectedCols  = this.props.selectedCols;
    let displayStart  = this.state.displayStart;
    let displaySize   = this.state.displaySize;
    let disabledLeft  = displayStart === 0;
    let disabledRight = (displayStart + displaySize) >= amtCols;
    let hiddenLeft    = selectedCols.filter( i => i < displayStart).length;
    let hiddenRight   = selectedCols.filter( i => i >= (displayStart+displaySize)).length;


    let chevronLeft = (
      <Chevron direction="left"
      handleClick={this.handleChevron}
      disabled={disabledLeft}
      hidden={hiddenLeft}
    />);

    let chevronRight = (
      <Chevron direction="right"
        handleClick={this.handleChevron}
        disabled={disabledRight}
        hidden={hiddenRight}
      />);
      let viewGrid = (
        <ViewGrid
          rows={this.props.rows}
          columns={this.props.columns}
          columnExtensions={this.props.columnExtensions}
          columnNames={this.props.columnNames}
          start={displayStart}
          size={displaySize}
          selectedIndex={selectedCols}
          handleSelect={this.handleSelect}
          langCol={this.props.langCol}
        />);


    return (
      <MediaQuery minWidth={1000}>
        {(matches) => {
          if(matches) {
            return (
              <Grid container
                direction="row"
                className="h-75"
              >
                <Grid item
                  xs={1}
                >
                  {chevronLeft}
                </Grid>
                <Grid item container
                  xs={12}
                  md={10}
                  className="outer-grid"
                >
                    {viewGrid}
                </Grid>
                <Grid item
                  xs={1}
                >
                  {chevronRight}
                </Grid>
              </Grid>
            )
          } else {
            return (
              <Grid container
                direction="column"
              >
                <Grid item container>
                  <Grid item className="border-right" style={{width: '50%'}}>
                    {chevronLeft}
                  </Grid>
                  <Grid item  style={{width: '50%'}}>
                    {chevronRight}
                  </Grid>
                </Grid>
                <Grid item>
                  {viewGrid}
                </Grid>
              </Grid>
            )
          }
        }}
      </MediaQuery>
    )
  }
}

class ViewGrid extends React.Component {

  constructor(props) {
    super(props);
    this.renderCell = this.renderCell.bind(this);
  }

  renderCell(props, selectedNames) {

    const column = props.column.name
    let style = {}
    let handler

    if (column === this.props.langCol) {
        style={backgroundColor: "#ccc"}
    } else if (selectedNames.includes(column)) {
        style={backgroundColor: "#cceeff"}
        handler=() => this.props.handleSelect(column)
    } else {
        handler=() => this.props.handleSelect(column)
    }
    return (
      <Table.Cell
        {...props}
        style={style}
        onClick={handler}
      />
    )
  }

  render(){

    var  columnNames    = this.props.columnNames.slice();
    const selectedIndex = this.props.selectedIndex;
    const start         = this.props.start;
    const size          = this.props.size;

    const selectedNames = selectedIndex.map( (index) => {
      return columnNames[index];
    });

    columnNames.splice(start, size);

    return(
      <TableGrid
        rows={this.props.rows}
        columns={this.props.columns}
        className="preview-area"
        style={{ minWidth: 0, width: window.innerWidth*0.6, display:'block', margin:'0 auto' }}
      >
          <Table
            columnExtensions={this.props.columnExtensions}
            cellComponent={(props) => this.renderCell(props, selectedNames)}
            className="preview-table"
          />

         <TableHeaderRow />
        <TableColumnVisibility
          hiddenColumnNames={columnNames}
        />
      </TableGrid>
    )
  }
}

export default PreviewArea;
