import React from "react";

import jStat from "jstat";
import RSlider from "./lib/Slider.js";
import SubTitle from "./lib/SubTitle.js";
import PropResults from "./lib/PropResults.js";
import ResultsExplanation from "./lib/ResultsExplanation.js";

import { PropDepExample } from "./lib/Example.js";
import Modal from "../lib/Modal.js";
import { Grid, Typography } from "@material-ui/core";
import SubMenu from "./lib/SubMenu.js";

class ProportionTestDep extends React.Component {
  /* Main component for the Dependent Proportion Test */

  constructor(props) {
    super(props);
    document.body.style.overflow = "auto";
    this.state = {
      prop_a: 70.7,
      prop_b: 81.6,
      n: 300,
      sig: 95,
      n_max: 5000,
      examples_modal: false
    };
    this.handler_prop_a = this.handler_prop_a.bind(this);
    this.handler_prop_b = this.handler_prop_b.bind(this);
    this.handler_n = this.handler_n.bind(this);
    this.handler_sig = this.handler_sig.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ examples_modal: true });
  }

  closeModal() {
    this.setState({ examples_modal: false });
  }

  handler_prop_a(input_value) {
    this.setState({
      prop_a: input_value
    });
  }

  handler_prop_b(input_value) {
    this.setState({
      prop_b: input_value
    });
  }

  handler_n(input_value) {
    this.setState({
      n: input_value
    });
  }

  handler_sig(input_value) {
    this.setState({
      sig: +input_value.toFixed(0)
    });
  }

  render() {
    const SUBTITLE =
      "This is a proportion test for dependent variables \n based on observations from the same sample.";
    const isMobile = window.innerWidth <= 950;

    // Calculate z_value

    const a_perc = +(this.state.prop_a / 100).toFixed(4);
    const b_perc = +(this.state.prop_b / 100).toFixed(4);

    var z_value = (
      (a_perc - b_perc) /
      Math.sqrt(
        (a_perc * (1 - a_perc) +
          b_perc * (1 - b_perc) +
          2 * (a_perc * b_perc)) /
          this.state.n
      )
    ).toFixed(3);

    // Calculate expected p and z values

    var p_value = jStat.ztest(z_value, 2).toFixed(3);
    var expected_z_value = Math.abs(
      jStat.normal.inv(0.5 - this.state.sig / 100 / 2, 0, 1).toFixed(2)
    );

    return (
      <div style={{ width: "90%", margin: "0 auto" }}>
        <Grid container direction="row" spacing={16}>
          <Grid item xs={12}>
            <SubMenu currentApp="dep" />
          </Grid>

          <Grid
            item
            container
            spacing={16}
            className="calc-container mx-auto rounded p-4 mb-5"
          >
            <Grid item xs={12}>
              <SubTitle text={SUBTITLE} isMobile={isMobile} />
            </Grid>

            <Grid item sm={6} xs={12}>
              <div id="prop-dep-a" className="rounded px-3">
                <RSlider
                  value={this.state.prop_a}
                  label="Proportion A"
                  label_mobile="A"
                  size={6}
                  colour="blue"
                  percentage={true}
                  handler={this.handler_prop_a}
                  id="prop-dep-a-slider"
                  tip={false}
                  min={0}
                  max={100}
                  tooltip={isMobile ? "Proportion A" : ""}
                />
              </div>
            </Grid>

            <Grid item sm={6} xs={12}>
              <div id="prop-dep-b" className="rounded px-3">
                <RSlider
                  value={this.state.prop_b}
                  label="Proportion B"
                  label_mobile="B"
                  size={6}
                  colour="cyan"
                  percentage={true}
                  handler={this.handler_prop_b}
                  id="prop-dep-b-slider"
                  tip={false}
                  min={0}
                  max={100}
                  tooltip={isMobile ? "Proportion B" : ""}
                />
              </div>
            </Grid>

            <Grid item xs={12} className="py-0">
              <div className="slider-container">
                <RSlider
                  id="prop-dep-n-slider"
                  value={300}
                  label="Sample Size"
                  label_mobile="N"
                  min={1}
                  size={12}
                  max={this.state.n_max}
                  colour="grey"
                  handler={this.handler_n}
                  tip={true}
                  decimals={false}
                  tooltip={isMobile ? "Sample Size" : ""}
                />
              </div>
            </Grid>

            <Grid item xs={12} className="py-0">
              <div className="slider-container">
                <RSlider
                  value={95}
                  label="Significance Level"
                  label_mobile="Sig"
                  min={80}
                  max={99}
                  size={12}
                  sig={true}
                  colour="grey"
                  percentage={true}
                  handler={this.handler_sig}
                  id="prop-dep-sig-slider"
                  tip={false}
                  decimals={false}
                  tooltip="<big>The level of confidence that the Confidence Interval will contain the true population proportion.</big>"
                />
              </div>
            </Grid>

            <Grid
              id="sig-results-box"
              className="rounded"
              container
              spacing={24}
            >
              <Grid item sm={6} xs={12}>
                <PropResults
                  z_value={z_value}
                  p_value={p_value}
                  sig={this.state.sig}
                  type="dep"
                  expected_z_value={expected_z_value}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <ResultsExplanation type="dep" />
                <Typography className="pt-3">
                  Need help? See{" "}
                  <b
                    onClick={this.openModal}
                    style={{ textDecoration: "underline", cursor: "pointer" }}
                  >
                    examples
                  </b>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          isOpen={this.state.examples_modal}
          closeModal={this.closeModal}
          contentLabel="Examples"
          isMobile={isMobile}
        >
          <PropDepExample
            sig={this.state.sig}
            n={this.state.n}
            prop_a={this.state.prop_a}
            prop_b={this.state.prop_b}
          />
        </Modal>
      </div>
    );
  }
}

export default ProportionTestDep;
