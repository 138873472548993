import { CONFIG } from '../../etc/config'
import axios from 'axios'

export default class API {

  getWordList(fullText, sortFunc) {

    const query_url = CONFIG.API.base + 'wordcloud/api/'

    var formData = new FormData()
    formData.append('text', fullText)

    fetch(query_url, {
      method: "POST",
      headers: {},
      body: formData,
      })
      .then(response => response.json())
      .then((response) => {
	  let list = response.list;
	  list = sortFunc(list)

    let all_words = Array.from(list)
	  this.setState({originalAllWords: all_words, allWords: list})
       })
      .catch((error) => {this.setState({error: error})})
  }

  getGraphN(svgBlob, exportType){

    const query_url = CONFIG.API.base + 'wordcloud/conversion/'

    const formData = new FormData();
    formData.append('svg', svgBlob);
    formData.append('type', exportType);

    fetch(
        query_url,
        { method: "POST",
          body: formData,
          headers: {},
        }).then((response) => { response.blob()
        }).then(
          (responseBlob) => {
            var url
            if (exportType === 'svg'){
              url = URL.createObjectURL(responseBlob);
            } else if (exportType === 'png'){
              url = URL.createObjectURL(responseBlob);
            }
            window.location = url;
          }
        ).catch(e => {console.log(e)});
  }

  getGraph(svgBlob, exportType) {

    const query_url = CONFIG.API.base + 'wordcloud/conversion/'

    const formData = new FormData();
    formData.append('svg', svgBlob);
    formData.append('type', exportType);
    axios.post(query_url, formData, {responseType:'blob'}).then(
      (response) => {
          var blob, url, filename;
        if(exportType === 'svg') {
            blob = new Blob([response.data], {type: "image/svg+xml;charset=utf-8;attachment;"});
            url = URL.createObjectURL(blob);
            filename = "wordcloud.svg";
        } else if(exportType === 'png') {
            blob = new Blob([response.data]);
            url = URL.createObjectURL(blob);
            filename = "wordcloud.png";
        }
        var downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    ).catch(e => {console.log(e)});
  }
}
