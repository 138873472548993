import { cloudStyle } from './defaults.js';
import { pngStyle } from './pngStyle.js';
import API from './api.js';

const NS = 'http://www.w3.org/2000/svg'

const findSVG = () => {
  var svgs = document.getElementsByTagName('svg')
  let svg;
  // hacky workaround to find the right svg
  for (var v in svgs){
      if (svgs[v].height !== undefined){
          if (svgs[v].height.animVal.valueAsString !== "1em"){
              svg = svgs[v];
          }
      }
  }
  return svg
}

const exportSVG = (svgType) => {
  // Export the graph as PNG or SVG
  let defs = document.createElementNS(NS, 'defs');
  let style = document.createElementNS(NS, 'style');
  let svg = findSVG();
  let doc, cdata
  const svgBoundingBox = svg.children[0].getBBox()

  svg = svg.cloneNode(true)
  svg.setAttribute('width', svgBoundingBox.width)
  svg.setAttribute('height', svgBoundingBox.height)
  svg.children[0].setAttribute(
    'transform', `translate(${-svgBoundingBox.x},${-svgBoundingBox.y})`)
  svg.setAttribute('xmlns', NS)

  doc = new DOMParser().parseFromString('', "application/xml")
  cdata = doc.createCDATASection(cloudStyle)
  style.append(cdata);
  svg.appendChild(defs);
  defs.appendChild(style);

  var svgBlob = new Blob([svg.outerHTML], {type:"image/svg+xml;charset=utf-8"})

  if (svgType === 'shape'){
    const a = new API()
    a.getGraph(svgBlob, 'svg');
  } else if (svgType === 'text') {
    var svgUrl = URL.createObjectURL(svgBlob)
    var downloadLink = document.createElement("a")
    downloadLink.href = svgUrl
    downloadLink.download = "export.svg"
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}

const exportPNG = () => {

  // Export the graph as PNG or SVG
  let defs = document.createElementNS(NS, 'defs');
  let style = document.createElementNS(NS, 'style');
  let svg = findSVG();
  let doc, cdata, svgData

  var parser = new DOMParser()
  doc = parser.parseFromString('<root></root>', "application/xml")
  cdata = doc.createCDATASection(pngStyle)
  style.append(cdata);
  svg.appendChild(defs);
  defs.appendChild(style);

  if (typeof window.XMLSerializer != "undefined") {
      svgData = (new XMLSerializer()).serializeToString(svg);
  } else if (typeof svg.xml != "undefined") {
      svgData = svg.xml;
  }

  var canvas = document.createElement("canvas");
  var svgSize = svg.getBoundingClientRect();
  canvas.width = svgSize.width;
  canvas.height = svgSize.height;
  var ctx = canvas.getContext("2d");

  var img = document.createElement("img");
  img.setAttribute("src",
      "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))) );

  img.onload = function() {
      ctx.drawImage(img, 0, 0);
      var imgsrc = canvas.toDataURL("image/png");
      var a = document.createElement("a")
      a.download = "export.png"

      // Microsoft Edge download
      if (window.navigator.userAgent.indexOf("Edge") > -1){
          fetch(imgsrc).then(response => response.blob()).then((imgBlob) => {
                          a.href = window.URL.createObjectURL(imgBlob)
                          a.click()
                        })
      } else {
          document.body.appendChild(a);
          a.href = imgsrc;
          a.click()
      }
  };
}

export { exportPNG, exportSVG };
