import ExcelTranslator from '../translator/ExcelTranslator';
import WordCloud from '../wordcloud/WordCloud';
import ConfidenceInterval from '../calculator/ConfidenceInterval';
import PropTestInd from '../calculator/PropTestInd';
import PropTestDep from '../calculator/PropTestDep';

import cloud from '../assets/img/Wordcloud.svg'
import translate from '../assets/img/Translator.svg'
import calculator from '../assets/img/Calculator.svg'
import sig from '../assets/img/Sig.svg'
import dep from '../assets/img/Dep.svg'
import topic_mod from '../assets/img/text_analytics.svg'
import indep from '../assets/img/Indep.svg'




const APP_CONFIG = {
    'main': {
      'title': 'Welcome to the Factworks Toolbox!',
      'description': 'Enjoy.',
      'path': '/',
      'name': 'Factworks Toolbox',
      'header_name': 'Toolbox',
      'active': true,
      'public': true,
      'apps': [
        {
            'name': 'Excel Translator',
            'header_name' : 'Translator',
            'component': ExcelTranslator,
            'path': '/translator',
            'short_description': "Translate verbatims in Excel files",
            'active': true,
            'public': false,
            'icon': translate,
            'group': 'main'
        },
        {
            'name': 'Wordcloud Generator',
            'header_name': 'Wordcloud',
            'component': WordCloud,
            'path': '/wordcloud',
            'short_description': "Create your own wordclouds",
            'active': true,
            'public': true,
            'icon': cloud,
            'group': 'main'
        },
        {
          name: 'Topic Modeling App',
          header_name: "Topic Modeling",
          component: null,
          path: 'https://myfactworks.shinyapps.io/LDA_ShinyApp/',
          short_description: "Explore your OE responses",
          active: true,
          public: false,
          icon: topic_mod,
          group: 'main',
        }
      ]
    },
    'calculator': {
      'title': 'Welcome to the Factworks Statistical Calculator!',
      'description': 'Tools for testing significant differences and calculating confidence intervals and sample sizes.',
      'short_description': 'Calculate differences and intervals',
      'path': '/calculator',
      'name': 'Statistical Calculator',
      'header_name': 'Calculator',
      'icon': calculator,
      'active': true,
      'public': true,
      'apps': [
        {
            'name': 'Confidence Interval',
            'component': ConfidenceInterval,
            'path': '/calculator/conf',
            'short_description': "Calculate confidence intervals",
            'active': true,
            'public': true,
            'icon': sig,
            'group': 'calculator'
        },
        {
            'name': 'Proportion Test - Dependent',
            'component': PropTestDep,
            'path': '/calculator/dep',
            'short_description': "Compare dependent samples",
            'active': true,
            'public': true,
            'icon': dep,
            'group': 'calculator'
        },
        {
            'name': 'Proportion Test - Independent',
            'component': PropTestInd,
            'path': '/calculator/ind',
            'short_description': "Compare independent samples",
            'active': true,
            'public': true,
            'icon': indep,
            'group': 'calculator'
        }
      ]
    },
}

export default APP_CONFIG
