import React, { Component } from 'react';

import XLSX from 'xlsx';
import PreviewArea from './PreviewArea.js';
import {
    Button,
    Grid
} from 'react-bootstrap';

import {FormControl, Select, MenuItem} from '@material-ui/core'

class PreviewFile extends Component {

  constructor(props){
    super(props)
    this.state = {
      selectedCols: {},
	    step: 1
    }
    this.selectCol = this.selectCol.bind(this);
    this.selectColList = this.selectColList.bind(this);
    this.resetSelectedCols = this.resetSelectedCols.bind(this);
    this.loadFile = this.loadFile.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.updateCloud = this.updateCloud.bind(this);
    this.getColumnString = this.getColumnString.bind(this);
    this.getColumnList = this.getColumnList.bind(this);
    this.generateMenuItems = this.generateMenuItems.bind(this);
    this.updateCloudList = this.updateCloudList.bind(this);
    this.setSheet = this.setSheet.bind(this);
  }

  selectCol(colIndex){
    /* Select column for text mode */
    const currentSheet = this.state.currentSheet;
    let selectedCols = this.state.selectedCols;
    const i = selectedCols[currentSheet].indexOf(colIndex)
    if (i > -1){
        selectedCols[currentSheet].splice(i, 1)
    } else {
        selectedCols[currentSheet].push(colIndex)
    }
    this.setState({
      selectedCols: selectedCols,
    })
  }

  selectColList(colIndex){
    /* Select column for list mode */
    let {wordCol, freqCol, currentSheet, selectedCols, step} = this.state
    const i = selectedCols[currentSheet].indexOf(colIndex)
    let newCol;
    if (i > -1){
        selectedCols[currentSheet].splice(i, 1)
        newCol = {}
        if (step === 1){
          wordCol = newCol
        } else if (step === 2){
          freqCol = newCol
        }
    } else {
        selectedCols = this.resetSelectedCols()
        selectedCols[currentSheet].push(colIndex)
        newCol = {'sheet': currentSheet, 'column': colIndex}
        if (step === 1){
          wordCol = newCol
        } else if (step === 2){
          freqCol = newCol
        }
    }
    this.setState({
      wordCol: wordCol,
      freqCol: freqCol,
      currentSheet: currentSheet,
      selectedCols: selectedCols,
    })
  }

  resetSelectedCols(){
    let selectedCols = {};
    for (var s in this.state.processed.SheetNames){
      let sheetName = this.state.processed.SheetNames[s];
      selectedCols[sheetName] = [];
    }
    return selectedCols
  }

  updateCloud(){
    /* Update wordcloud in TEXT mode */
    var newText = '';
    for (var i in this.state.sheetNames){
      const sheetName = this.state.sheetNames[i];
      const cols = this.state.selectedCols[sheetName];
      for (var c in cols){
         const colIndex = cols[c];
         newText = newText.concat(' ' + this.getColumnString(sheetName, colIndex));
      }
    }
    this.props.updateText(newText);
    this.props.loadPanel(1);
  }

  updateCloudList(){
    /* Update wordcloud in LIST mode */
    const wordList = this.getColumnList(
      this.state.wordCol.sheet,
      this.state.wordCol.column
    )
    const freqList = this.getColumnList(
      this.state.freqCol.sheet,
      this.state.freqCol.column
    )
    let newWordList = []
    for (var i in wordList){
      const word = wordList[i];
      const freq = freqList[i];
      if (word !== 'N/A' && freq !== 'N/A'){
        newWordList.push({'word': word, 'text': word, 'value': freq})
      }
    }
    this.props.setWordlist(newWordList);
    this.props.loadPanel(1)
  }

  nextStep(){
    /* Move to next step in LIST mode */
    this.setState({
      step: 2,
      selectedCols: this.resetSelectedCols(),
    })
  }

  getColumnList(sheetName, column){
    /* Return the column as a list */
    let sheet = this.state.processed.Sheets[sheetName]
    let columnList = [];
    let range = XLSX.utils.decode_range(sheet['!ref']);
    let [firstRow, lastRow] = [range.s.r, range.e.r];

    for (var r = firstRow+1; r <= lastRow; r++) {
        let cellRef   = XLSX.utils.encode_cell({r: r, c: column});
        let cell      = sheet[cellRef];
        if (typeof cell == 'undefined') {
          columnList.push('N/A')
        } else {
          columnList.push(cell.v)
        }
    }
    return columnList;
  }

  getColumnString(sheetName, column){
    let sheet = this.state.processed.Sheets[sheetName]
    let columnString = '';
    let range = XLSX.utils.decode_range(sheet['!ref']);
    let [firstRow, lastRow] = [range.s.r, range.e.r];
    for (var r = firstRow+1; r <= lastRow; r++) {
        let cellRef   = XLSX.utils.encode_cell({r: r, c: column});
        let cell      = sheet[cellRef];
        if (typeof cell != 'undefined') {
          columnString = columnString.concat(' ' + cell.v);
        }
    }
    return columnString;
  }

  getPreview(sheet) {

    let rows = [];
    let range = XLSX.utils.decode_range(sheet['!ref']);
    let [firstCol, lastCol, firstRow, lastRow] = [
      range.s.c, range.e.c, range.s.r, range.e.r];

    if(lastRow > 10) { lastRow = 10; }
    var columns = [];

    for (var s = firstCol; s <= lastCol; s++) {
      let col      = {}
      col['name']  = 'col'+s;
      let cellRef  = XLSX.utils.encode_cell({r:0, c:s});
      let title    = sheet[cellRef];
      col['title'] = (typeof title === 'undefined')? 'Col'+s : title.v ;
      columns.push(col);
    }

    for (var r = firstRow+1; r <= lastRow; r++) {
      let rowData = {};
      for (var c = firstCol; c <= lastCol; c++) {
        let colName = 'col'+(c);
        let cellRef   = XLSX.utils.encode_cell({r: r, c: c});
        let cell      = sheet[cellRef];
        let cellData;
        if (typeof cell === 'undefined') {
          cellData = '';
        } else {
          cellData = cell.v;
        }
        rowData[colName] = cellData;
      }
      rows.push(rowData);
    }
    const preview = {columns: columns, rows: rows};
    return preview;

  }

  loadFile(){

    const processed = XLSX.read(this.props.binary, {type: "binary"})
    const sheets = processed.SheetNames
    const sheetPreviews = sheets.map((sheetName) => {
      let sheet = processed.Sheets[sheetName]
      let data = this.getPreview(sheet)
      return { sheetName: sheetName, preview: data }
    });

    let selectedCols = {};
    for (var s in processed.SheetNames){
      let sheetName = processed.SheetNames[s];
      selectedCols[sheetName] = [];
    }

    const currentSheet = sheets[0]
    let sheet        = sheetPreviews.find(
      (s) => { return s.sheetName === currentSheet} );
    const columnNames = sheet.preview.columns.map((el) => el.name);

    this.setState({
      processed: processed,
      sheetNames: processed.SheetNames,
      previews: sheetPreviews,
      currentSheet: currentSheet,
      selectedCols: selectedCols,
      columnNames: columnNames
    })

  }

  componentDidMount(){
    this.loadFile();
  }

  generateMenuItems(){
    const sheetMenuItems = this.state.sheetNames.map( (el, index) => {
      let indicator = this.state.selectedCols[el].length > 0 ?
        (<span className="sheet-indicator">({this.state.selectedCols[el].length})</span>)
      : '' ;
      return (
        <MenuItem value={index} key={index}>{el}&nbsp;&nbsp;{indicator}</MenuItem>
      )
    })
    return sheetMenuItems;
  }

  generateButton(){
     if (this.props.type === "text"){
     	return (<Button
               bsStyle="default"
               bsSize="medium"
               onClick={() => this.updateCloud()}>Generate</Button>)
     } else if (this.props.type === "list"){
        if (this.state.step === 1){
     	    return (<Button
               bsStyle="default"
               bsSize="medium"
               onClick={() => this.nextStep()}>Next</Button>)
        } else if (this.state.step === 2){
     	    return (<Button
               bsStyle="default"
               bsSize="medium"
               onClick={() => this.updateCloudList()}>Generate</Button>)
        }
     }
  }

  setSheet(index){
    this.setState({
      currentSheet: this.state.processed.SheetNames[index],
    })
  }

  render(){

      if (this.state.sheetNames === undefined){
        return (<div>Loading...</div>)
      }

      const selectedSheetIndex = this.state.sheetNames.findIndex(
	       (s) => { return s === this.state.currentSheet });

      const button = this.generateButton();

      let selectCol;
      let helpText;
      if (this.props.type === "text"){
        selectCol = this.selectCol;
        helpText = 'Select the column(s) with text to use (click on the cells)';
      } else if (this.props.type === "list"){
        if (this.state.step === 1){
          helpText = '1. Select the column with words (click on the cells)';
        } else {
          helpText = '2. Select the column with frequencies (click on the cells)'
        }
        selectCol = this.selectColList;
      }

      return(
          <div id="preview-file">

              <Grid container direction="row" className="h-15 mb-5">
              <Grid item xs={5}>
                <div id="wc_help" className="float-left ml-5">
                  <p id="wc-help-text">{ helpText } </p>
                </div>
              </Grid>
              <Grid item xs={5}>
                  <FormControl className="float-right w-25 mx-auto mr-5">
                    <Select
                      value={selectedSheetIndex}
                      onChange={(event) => this.setSheet(event.target.value)}
                      inputProps={{id: 'sheet', name: 'sheet'}}
                      >
                      {this.generateMenuItems()}
                    </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={1}>
                <span>&nbsp;</span>
              </Grid>
              </Grid>

              <PreviewArea
                  sheetNames={this.state.sheetNames}
                  previews={this.state.previews}
                  currentSheet={this.state.currentSheet}
                  selectedCols={this.state.selectedCols}
                  displaySize={7}
                  selectCol={selectCol}
		              columnNames={this.state.columnNames}
              />

              <Grid container direction="row" className="h-10 mt-5">
                <Grid item xs={10}>
                  <div id="wc_button" className="float-right w-10">
	                   { button }
                  </div>
                </Grid>
              </Grid>
          </div>
      )
  }
}

export default PreviewFile;
