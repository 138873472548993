import React from 'react'

import {
    Grid,
    Button,
    Drawer,
    ListItem } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

class BurgerMenu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  toggleDrawer(open) {
    this.setState({ open: open });
  };

  generateItems(links) {
    return links.map((l, index) => {
      if(l) {
        return (
          <ListItem key={index}>
            <Grid container justify="center" className="burger-item">
              {l}
            </Grid>
          </ListItem>
        )
      } else {
        return '';
      }
    });
  }

  render() {
    const links = this.props.links;
    const items = this.generateItems(links);
    const logout = (
      <ListItem key="logout" className="burger-item">
        <Grid container justify="center">
          {this.props.logout}
        </Grid>
      </ListItem>
    )

    return(
      <div>
        <Button onClick={() => this.toggleDrawer(true)} className=" m-0 p-0 clean-button">
          <MenuIcon className="burger-menu-icon clean-button m-auto" />
        </Button>
          <Drawer open={this.state.open}
            onClose={() => this.toggleDrawer(false)}
            PaperProps={{className: "burger-menu clean-button"}}
            tabIndex={0}

          >
            <div
              className="clean-button h-100 pt-5"
              tabIndex={0}
              onClick={() => this.toggleDrawer(false)}
              onKeyDown={() => this.toggleDrawer(false)}
            >
              {items}
              {logout}
            </div>
          </Drawer>
      </div>
    )
  }
}
export default BurgerMenu;
