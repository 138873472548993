import './assets/css/ContextMenu.css';

import React, { Component } from 'react';
import ReactDataGrid, { Row, Cell } from 'react-data-grid';
import splitIcon from './assets/img/link-1.svg';
import splitIconAlt from './assets/img/link-broken-1.svg';
import { Typography, Tooltip } from '@material-ui/core'
const { Menu, Toolbar, Data: { Selectors } } = require('react-data-grid-addons');
const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;


const columns = [
  {
    key: 'unmerge',
    name: ' ',
    editable: false,
    sortable: true,
    width: 40,
  },
  {
    key: 'word',
    name: 'Word',
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    key: 'freq',
    name: 'Freq.',
    editable: true,
    sortable: true,
  },
];

function ExampleContextMenu({
  idx,
  id,
  rowIdx,
  onRowHide,
  generateRows,
  handleUnmerge,
  }) {
  const rows = generateRows()
  let menu_name;
  let split_item;

  if (rowIdx === -1){
    menu_name = '';
  } else {
    let row = rows[rowIdx];
    if (row !== undefined) {
      menu_name = row.isDisplayed === true ? "Hide Word" : "Show Word"
      split_item = (row.unmerge !== '')
      ?
      <MenuItem data={{ rowIdx, idx }} onClick={() => handleUnmerge(row.word)}>
        <Typography component="span">
          Split
        </Typography>
      </MenuItem>
      : '' ;
    }
  }

  return (
    <ContextMenu id={id}>
      <MenuItem data={{ rowIdx, idx }} onClick={onRowHide}>
        <Typography component="span">
          {menu_name}
        </Typography>
      </MenuItem>
      <Typography component="span">
        {split_item}
      </Typography>
    </ContextMenu>
  );
}

class CellRenderer extends Component {
  getCellClassName() {
    const row = this.props.rowData;
    let className = "border-left-0 border-right-0 ";
    if (row.isDisplayed) {
      className += " wc-displayedRowBg";
    }
    if((this.props.column.key === 'unmerge') && (this.props.rowData.unmerge !== '')) {
      className += " p-0"
    }

    return className;
  }

  render() {
    return (<Cell title={this.props.rowData[this.props.column.key]} className={this.getCellClassName().concat(" bg-white")} {...this.props} />)
  }
}

class RowRenderer extends Component {
  render() {
    return (
      <Row cellRenderer={CellRenderer}
        ref={ node => this.row = node }
        {...this.props}
        className="border-right-0 border-left-0"
      />
    );
  }
}

class WordList extends Component {

  constructor(props) {
      super(props);
      this.state = {
        wordList: this.props.wordList,
        displayList: this.props.displayList,
        toMerge: [],
        columns: columns,
        filters: {},
	      sortColumn: 'freq',
	      sortDirection: 'DESC'
      }
      this.generateRows    = this.generateRows.bind(this);
      this.handleMerge  = this.handleMerge.bind(this);
      this.handleRowSelect  = this.handleRowSelect.bind(this);
      this.handleRowDeselect  = this.handleRowDeselect.bind(this);
      this.handleFilter  = this.handleFilter.bind(this);
      this.updateWordList  = this.updateWordList.bind(this);
      this.handleSort  = this.handleSort.bind(this);
      this.handleClearFilters  = this.handleClearFilters.bind(this);
      this.hideRow = this.hideRow.bind(this);
      this.hideMultipleRows = this.hideMultipleRows.bind(this);

      this.title = "Word List"

      let hideButton = (
        <button type="button" className="btn ml-2" onClick={this.hideMultipleRows}>Hide/Show</button>
      );

      this.toolbar = <Toolbar
        addRowButtonText="Merge"
        onAddRow={this.handleMerge}
        children={hideButton}
      />
    }

  generateRows () {
      let rows             = [];
      const wordList       = this.props.wordList;
      const displayWords   = this.props.displayList;
      const filters        = this.state.filters;

      for (var i in wordList) {
        let wordObj     = wordList[i];
        let word        = wordObj.word;

        let freq        = wordObj.value;
        let isDisplayed = displayWords.includes(wordObj);

        let unmerge;
        if('mergeOf' in wordObj) {
          let eachWord = wordObj.mergeOf.map((word) => {
            return word.word
          });


          let title = (
            <div>
              <Typography color="textSecondary" variant="h6">
                Words:
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {eachWord.join(', ')}
              </Typography>
            </div>)

          unmerge = (

            <Tooltip title={title} classes={{tooltip: "tooltip-bg"}}>
              <img
                alt="Split"
                className="split-icon ml-2"
                width="60%"
                src={splitIcon}
                onMouseOver={(e) => (e.currentTarget.src = splitIconAlt)}
                onMouseOut={(e) => (e.currentTarget.src = splitIcon)}
                onClick={() => this.props.handleUnmerge(word)}/>
            </Tooltip>)


        } else {
          unmerge = '';
        }

        let row = {
          word: word,
          freq: freq,
          unmerge: unmerge,
          isDisplayed: isDisplayed,
        }
        rows.push(row);
      }


      /* Sort rows */
      const { sortDirection, sortColumn } = this.state
      const comparer = (a, b) => {
        let first, second
        if (sortColumn === "word") {
          first = a["word"].toLowerCase()
          second = b["word"].toLowerCase()
        } else {
          first = a[sortColumn]
          second = b[sortColumn]
        }
        if (sortDirection === "ASC"){
          return (first > second) ? 1 : -1;
        } else if (sortDirection === "DESC"){
          return (first <= second) ? 1 : -1;
	      }
      }
      rows.sort(comparer);
      return Selectors.getRows({rows, filters})
    }

  handleMerge() {
      var equivalents = [];
      var allWords    = this.props.wordList;
      var toRemove    = [];
      var newPhrase   = ''
      var newFreq     = 0;
      var highestFreq = 0;
      const toMerge = this.state.toMerge;

      // find the equiv object in Generator's wordlist
      for (let index in toMerge) {
        let element    = toMerge[index];
        let equivalent = allWords.findIndex( (w) => {
            return (w.word === element)
          }
        );
        equivalents.push(equivalent);
      }

      // determine which new word/freq to display and add objects for removal
      for (let index in equivalents) {
        let equivalent = allWords[equivalents[index]];
        let word       = equivalent.word
        let thisFreq   = equivalent.value

        toRemove.push(equivalent);

        if (thisFreq > highestFreq){
          highestFreq  = thisFreq;
          newPhrase    = word;
        }
        newFreq       += thisFreq;
      }

      const newEntry = {
        word: newPhrase,
        value: newFreq,
        mergeOf: toRemove,
      }

      this.props.handleMerge(toRemove, newEntry)

      this.setState({
        toMerge: [],
        rows: this.generateRows()
      })
    }

  hideRow(id){
    const row = this.generateRows()[id]
    const {word, freq} = row
    this.props.updateExcludedWords(this.generateRows, {word, freq})
  }

  hideMultipleRows() {
    let toHide = this.state.toMerge;
    let wordList = this.generateRows();

    for (let i in toHide) {
      let word = toHide[i];
      let index = wordList.findIndex( (w) => {
        return w.word === word
      });

      if (index !== -1) {
        this.hideRow(index)
      }
    }

    this.setState({ toMerge: [] });
  }

  handleRowSelect(obj){
      let toMerge = this.state.toMerge;
      const word = obj[0].row.word;
      if (!toMerge.includes(word)){
        toMerge.push(word);
        this.setState({toMerge})
      }
    }

  handleRowDeselect(obj){
      let toMerge = this.state.toMerge
      const word = obj[0].row.word
      const index = toMerge.indexOf(word)
      if (index !== -1){
        toMerge.splice(index, 1);
        this.setState({toMerge})
      }
    }

  handleFilter (filter) {
      let newFilters = Object.assign({}, this.state.filters);
      if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
      } else {
        delete newFilters[filter.column.key];
      }
      this.setState({ filters: newFilters });
    }

  updateWordList ({ fromRow, toRow, updated }) {
      const prevState = this.generateRows()[fromRow];
      this.props.updateWordList(this.generateRows, { prevState, updated })
    }

  handleClearFilters () {
      this.setState({ filters: {} });
    }

  handleSort(column, direction){
      this.setState({
        sortColumn: column,
        sortDirection: direction
      })
    }

  componentDidMount() {
    this.openGrid.onToggleFilter()
  }

  render() {
    /* this.generateRows(); */

    const rows = this.generateRows();
    function rowGetter(i){
      return rows[i]
    }

    const selected = rows.filter( (r) => {
      return this.state.toMerge.includes(r.word);
    })

    const selectedIndexes = selected.map( (s) => {
      return rows.indexOf(s);
    })

      //let filterField = (<>)

    const LIST_HEIGHT = window.innerHeight*0.675 < 620 ? 620 : window.innerHeight*0.675

    return(
      <div className="mt-3 mr-0">
          <div className="row border-bottom pb-2 mb-2 ml-1 mr-1">
            <Typography component="span" variant="h5" align="center">
              {this.title}
            </Typography>
          </div>
          <ReactDataGrid
            ref={(datagrid) => { this.openGrid = datagrid; }}
            enableCellSelect={true}
            columns={this.state.columns}
            rowGetter={rowGetter}
            rowsCount={rows.length}
            onGridRowsUpdated={this.updateWordList}
            onGridSort={this.handleSort}
            onAddFilter={this.handleFilter}
            onClearFilters={this.handleClearFilters}
            rowRenderer={RowRenderer}
            toolbar={this.toolbar}
            sortColumn={this.state.sortColumn}
            sortDirection={this.state.sortDirection}
            minHeight={LIST_HEIGHT}
	          contextMenu={
	            <ExampleContextMenu
		            onRowHide={(e, {rowIdx}) => this.hideRow(rowIdx)}
		            generateRows={this.generateRows}
                handleUnmerge={this.props.handleUnmerge}
	            />
	          }
	          RowsContainer={ContextMenuTrigger}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: this.handleRowSelect,
              onRowsDeselected: this.handleRowDeselect,
              selectBy: { indexes: selectedIndexes },
            }}
          />
        </div>
      );
    }
  }

export default WordList;
