import React, { Component } from 'react';
import close from '../../assets/img/close.svg'
import { Grid } from '@material-ui/core';
import { DownloadArea, SourceLang, CustomSelect } from './ToolbarComponents.js';
import MediaQuery from 'react-responsive';

class SettingsToolbar extends Component {
  constructor(props) {
    super(props);
    this.generateSheetMenuItems   = this.generateSheetMenuItems.bind(this);
    this.generateTransMethodItems = this.generateTransMethodItems.bind(this);
    this.generateSourceLangItems  = this.generateSourceLangItems.bind(this);
    this.generateTargetLangItems  = this.generateTargetLangItems.bind(this);
    this.renderDesktop            = this.renderDesktop.bind(this);
    this.renderMobile             = this.renderMobile.bind(this);
  }

  generateSheetMenuItems() {
    let selected;
    let items = this.props.sheetNames.map( (el, index) => {
        let indicator = el.selected ?
          (<span className="sheet-indicator">{'('}{el.selected}{')'}</span>)
        : '' ;
        let label = (<div>{el.name} {indicator}</div>)
        let item  = { value: index+1, label: label, selected: false }
        if(this.props.currentSheet === el.name) {
          item.selected = true
          selected = item
        }
        return item;
      })

      return { items, selected }

  };

  generateTransMethodItems () {
    let selected;
    let items = this.props.transMethods.map( (el) => {
      let item = {  value: el.val, label: el.name, disabled: false, selected: false}
      if(el.val === this.props.transMethod.val) {
        item.selected = true
        selected = item
      }
      return item;
    })

    return { items, selected }
  }

  generateSourceLangItems() {
    let items;
    let selected;
    let label

    if (this.props.isGlobal) {
      let sourceLangPresent = (this.props.sourceLang !== null)
      label = "Source language";
      items = this.props.sourceLangs.map( (el) => {
        let disabled = sourceLangPresent ? el.code === this.props.targetLang.code : false;
        let item     = { value: el.code, label: el.name, disabled: disabled, selected: false }
        if (sourceLangPresent && (el.code === this.props.sourceLang.code)) {
          item.selected = true
          selected      = item
        }
        return item;
      });

    } else {
      label = "Select column";
      items = this.props.columns.map( (c) => {
        let item = { value: c.name, label: c.title, disabled: false }
        if (this.props.langCol && (c.name === this.props.langCol)) {
          selected = item;
        }
        return item;
      });
    }

    return { items, selected, label };
  }

  generateTargetLangItems() {
    let selected;
    let label = "Target language";
    let sourceLangPresent = (this.props.sourceLang !== null)
    let items = this.props.targetLangs.map( (el) => {
      let disabled = sourceLangPresent ? el.code === this.props.sourceLang.code : false;
      let item     = { value: el.code, label: el.name, disabled: disabled, selected: false }
      if (el.code === this.props.targetLang.code) {
        item.selected = true
        selected      = item
      }
      return item;
    });
    return { items, selected, label };
  }

  renderDesktop(sheetMenuData, transMethodData, sourceLangData, targetLangData) {
    return (
      <Grid container
        direction="row"
      >
        {/* ============ CANCEL BUTTON ============ */}
        <Grid item
          sm={12}
          md={1}
          className="border-right w-100"
        >
          <div className="d-flex justify-content-center w-100 h-100 clickable" onClick={this.props.cancel}>
            <img
              src={close}
              className="m-auto"
              alt="Back" style={{height: "50px"}}
            />
          </div>
        </Grid>
        {/* ============ SHEET SELECTOR ============ */}
        <Grid item
          xs={6}
          md={2}
          className="border-right p-2"
        >
          <CustomSelect
            className="w-75 mx-auto"
            selected={sheetMenuData.selected}
            setVal={this.props.setSheet}
            items={sheetMenuData.items}
            label="Sheet"
            searchable={false}
          />
        </Grid>
        {/* ============ TRANSLATION METHOD ============ */}
        <Grid item
          xs={6}
          md={2}
          className="border-right p-2"
        >
          <CustomSelect
            className="w-75 mx-auto"
            items={transMethodData.items}
            setVal={this.props.setTransMethod}
            selected={transMethodData.selected}
            label="Translation method"
            searchable={false}
          />
        </Grid>
        {/* ============ SOURCE LANGUAGE ============ */}
        <Grid item
          xs={6}
          md={4}
          className="border-right p-2"
        >
          <SourceLang
            isGlobal={this.props.isGlobal}
            setVal={this.props.setIsGlobal}
            label={sourceLangData.label}
            selected={sourceLangData.selected}
            items={sourceLangData.items}
            setSourceLang={this.props.setSourceLang}
            setIsGlobal={this.props.setIsGlobal}
            searchable={true}
          />
        </Grid>
        {/* ============ TARGET LANGUAGE ============ */}
        <Grid item
          xs={6}
          md={2}
          className="border-right p-2"
        >
          <CustomSelect
            className="w-75 mx-auto"
            items={targetLangData.items}
            setVal={this.props.setTargetLang}
            selected={targetLangData.selected}
            label={targetLangData.label}
            searchable={false}
          />
        </Grid>
        {/* ============ DOWNLOAD ============ */}
        <Grid item
          xs={12}
          md={1}
          className="p-2"
        >
          <DownloadArea
            allowSend={this.props.allowSend}
            sendFile={this.props.sendFile}
          />
        </Grid>
      </Grid>
    );
  }

  renderMobile(sheetMenuData, transMethodData, sourceLangData, targetLangData) {
    return (
      <Grid container
        direction="row rounded"
      >
        {/* ============ CANCEL BUTTON ============ */}
        <Grid item
          md={12}
          className="border-bottom w-100 p-2"
        >
          <div className="d-flex justify-content-center w-100 h-100 clickable" onClick={this.props.cancel}>
            <img
              src={close}
              className="m-auto"
              alt="Back" style={{height: "50px"}}
            />
          </div>
        </Grid>
        {/* ============ SHEET SELECTOR ============ */}
        <Grid item
          md={12}
          className="border-bottom w-100 pb-2"
        >
          <CustomSelect
            className="w-75 mx-auto"
            selected={sheetMenuData.selected}
            setVal={this.props.setSheet}
            items={sheetMenuData.items}
            label="Sheet"
            searchable={false}
          />
        </Grid>
        {/* ============ TRANSLATION METHOD ============ */}
        <Grid item
          md={12}
          className="pb-2 w-100 border-bottom"
        >
          <CustomSelect
            className="w-75 mx-auto"
            items={transMethodData.items}
            setVal={this.props.setTransMethod}
            selected={transMethodData.selected}
            label="Translation method"
            searchable={false}
          />
        </Grid>
        {/* ============ SOURCE LANGUAGE ============ */}
        <Grid item
          md={12}
          className="border-bottom w-100 pb-2"
        >
          <SourceLang
            isGlobal={this.props.isGlobal}
            setVal={this.props.setIsGlobal}
            label={sourceLangData.label}
            selected={sourceLangData.selected}
            items={sourceLangData.items}
            setSourceLang={this.props.setSourceLang}
            setIsGlobal={this.props.setIsGlobal}
            searchable={true}
          />
        </Grid>
        {/* ============ TARGET LANGUAGE ============ */}
        <Grid item
          md={12}
          className="w-100 border-bottom pb-2"
        >
          <CustomSelect
            className="w-75 mx-auto"
            items={targetLangData.items}
            setVal={this.props.setTargetLang}
            selected={targetLangData.selected}
            label={targetLangData.label}
            searchable={false}
          />
        </Grid>
        {/* ============ DOWNLOAD ============ */}
        <Grid item
          sm={12}
          md={1}
          className="w-100 pb-2"
        >
          <DownloadArea
            allowSend={this.props.allowSend}
            sendFile={this.props.sendFile}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const sheetMenuData      = this.generateSheetMenuItems();
    const transMethodData    = this.generateTransMethodItems();
    const sourceLangData     = this.generateSourceLangItems();
    const targetLangData     = this.generateTargetLangItems();

    return (
      <MediaQuery minWidth={500}>
        {(matches) => {
          if(matches) {
            return this.renderDesktop(sheetMenuData, transMethodData, sourceLangData, targetLangData)
          } else {
            return this.renderMobile(sheetMenuData, transMethodData, sourceLangData, targetLangData)
          }
        }}
      </MediaQuery>
    );
  }
}

export default SettingsToolbar;
