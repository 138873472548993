
import React from 'react'
import { Button } from 'react-bootstrap';

const SelectFileInput = ({file, setUploadType}) => (
    <div id="select-file-input">
        <p>What's in this file?</p>
            <Button
                bsStyle="default"
                bsSize="medium" block
                onClick={() => setUploadType('text')}>Plain Text</Button>

            <Button
                bsStyle="default"
                bsSize="medium"
                block
                onClick={() => setUploadType("list")}>Word/Frequency List</Button>

        <br />
        <p className="filename">{file.name}</p>
    </div>
)

export {SelectFileInput}