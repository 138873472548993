import React, { Component } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import SettingsToolbar from "./lib/SettingsToolbar.js";
import PreviewArea from "./lib/PreviewArea.js";
import FileInput from "./lib/FileInput.js";
import API from "./lib/api";
import { google_languages } from "./lib/constants.js";

import { LoaderOverlay, SuccessOverlay, ErrorOverlay } from "./lib/Overlay.js";

class ExcelTranslator extends Component {
  constructor(props) {
    super(props);
    var sourceLangs = [];
    for (var l in google_languages) {
      sourceLangs.push({ name: google_languages[l], code: l });
    }
    this.isoObjList = sourceLangs;
    this.de = this.isoObjList.find(l => {
      return l.code === "de";
    });
    this.en = this.isoObjList.find(l => {
      return l.code === "en";
    });

    this.transMethods = [
      { name: "Neural (EN)", val: "nmt" },
      { name: "Phrase-based", val: "pbt" }
    ];

    this.state = {
      rawFile: null,
      file: null,
      previews: null,
      currentSheet: null,
      sheetNames: null,
      selectedCols: {},
      transMethod: this.transMethods[0],
      transMethods: this.transMethods,
      sourceLangs: this.isoObjList,
      sourceLang: {
        isGlobal: true,
        langCols: {},
        lang: null
      },
      targetLangs: this.isoObjList,
      targetLang: this.en,
      waiting: false,
      wrongFileType: null
    };

    this.api = new API();

    this.setFileInfo = this.setFileInfo.bind(this);
    this.preparePreviewProps = this.preparePreviewProps.bind(this);
    this.selectCol = this.selectCol.bind(this);
    this.setSheet = this.setSheet.bind(this);
    this.setTransMethod = this.setTransMethod.bind(this);
    this.setSourceLang = this.setSourceLang.bind(this);
    this.setIsGlobal = this.setIsGlobal.bind(this);
    this.setTargetLang = this.setTargetLang.bind(this);
    this.makeObject = this.makeObject.bind(this);
    this.send = this.api.sendRequest.bind(this);
    this.finishJob = this.finishJob.bind(this);
    this.wrongFileType = this.wrongFileType.bind(this);
  }

  setFileInfo(file, previews, rawFile) {
    const sheetNames = file.SheetNames;
    const selectedCols = {};
    const sourceLang = this.state.sourceLang;
    const langCols = sourceLang.langCols;

    for (var s in sheetNames) {
      let sheetName = sheetNames[s];
      selectedCols[sheetName] = [];
      langCols[sheetName] = null;
    }

    this.setState({
      rawFile: rawFile,
      file: file,
      sheetNames: sheetNames,
      previews: previews,
      currentSheet: sheetNames[0],
      selectedCols: selectedCols
    });
  }

  setIsGlobal(val) {
    const bool = val === "global";
    let sourceLang = this.state.sourceLang;
    sourceLang["isGlobal"] = bool;
    this.setState({ sourceLang: sourceLang });
  }

  setTargetLang(val) {
    let langObj = this.state.targetLangs.find(l => {
      return l.code === val;
    });
    this.setState({ targetLang: langObj });
  }

  setSourceLang(val) {
    let sourceLang = this.state.sourceLang;

    if (this.state.sourceLang.isGlobal) {
      let langObj = this.state.sourceLangs.find(l => {
        return l.code === val;
      });
      sourceLang["lang"] = langObj;
    } else {
      let langCols = sourceLang.langCols;
      let currentSheet = this.state.currentSheet;
      langCols[currentSheet] = val;
      sourceLang["langCols"] = langCols;
    }
    this.setState({ sourceLang: sourceLang });
  }

  setTransMethod(val) {
    let transMethod = this.state.transMethods.find(m => m.val === val);
    this.setState({ transMethod: transMethod });
  }

  setSheet(index) {
    const sheetNames = this.state.sheetNames;
    const newSheet = sheetNames[index - 1];
    this.setState({ currentSheet: newSheet });
  }

  selectCol(index) {
    var currentSheet = this.state.currentSheet;
    var selectedCols = this.state.selectedCols;
    var selected = selectedCols[currentSheet];
    var indexOf = selected.indexOf(index);

    if (indexOf === -1) {
      selected.push(index);
    } else {
      selected.splice(indexOf, 1);
    }

    this.setState({ selectedCols });
  }

  makeObject() {
    const isGlobal = this.state.sourceLang.isGlobal;
    const inputLangSource = isGlobal ? "GLOBAL" : "ROW";
    const translationMethod = 'nmt'
    const previews          = this.state.previews;
    const selectedCols      = this.state.selectedCols;
    const langCols          = this.state.sourceLang.langCols;
    const sourceLang        = (isGlobal) ? this.state.sourceLang.lang.code : "";
    const outputLang        = this.state.targetLang.code;
    var inputSheets         = {}

    for (var p in previews ) {
      let preview   = previews[p];
      let sheetName = preview.sheetName;
      let columns = preview.preview.columns;
      let selected = selectedCols[sheetName];
      //was storing col names, but api requires col titles. Code below does the translation
      if (selected.length > 0) {
        let sheetData = {};
        let inputCols = selected.map(index => {
          let col = columns[index];
          return col.title;
        });

        if (!isGlobal) {
          let colName = langCols[sheetName];
          let langCol = columns.find(col => {
            return col.name === colName;
          });
          sheetData["input_lang_col"] = langCol.title;
        }

        sheetData["input_cols"] = inputCols;
        inputSheets[sheetName] = sheetData;
      }
    }
    var obj = {
      selectedFile: this.state.rawFile,
      model: translationMethod,
      params: {
        lang_detect: false,
        input_lang_source: inputLangSource,
        input_lang: sourceLang,
        output_lang: outputLang,
        input_sheets: inputSheets
      }
    };

    this.send(obj.params, obj.selectedFile);

    this.setState({
      waiting: true
    });
  }

  finishJob() {
    this.setState({
      rawFile: null,
      file: null,
      previews: null,
      currentSheet: null,
      sheetNames: null,
      selectedCols: {},
      transMethod: this.transMethods[0],
      transMethods: this.transMethods,
      sourceLangs: this.isoObjList,
      sourceLang: {
        isGlobal: true,
        langCols: {},
        lang: this.de
      },
      targetLangs: this.isoObjList,
      targetLang: this.en,
      waiting: false,
      response: null,
      error: null,
      wrongFileType: null
    });
  }

  wrongFileType(type) {
    this.setState({ wrongFileType: type });
  }

  preparePreviewProps() {
    let currentSheet = this.state.currentSheet;
    let sheet = this.state.previews.find(s => {
      return s.sheetName === currentSheet;
    });
    let columns = sheet.preview.columns;
    let rows = sheet.preview.rows;
    let columnNames = columns.map(el => {
      return el.name;
    });

    let selectedCols = this.state.selectedCols[currentSheet];
    let filteredColumns = columns.slice(); // shallow copy

    /* REMOVING SELECTED COLUMNS FROM POSSIBLE LANG COLUMNS*/
    for (var index in selectedCols) {
      let selected = selectedCols[index];
      filteredColumns.splice(selected, 1);
    }

    let columnExtensions = columnNames.map(el => {
      return { name: el, wordWrapEnabled: true };
    });

    /* MARKING SOURCE LANG IN TARGET LANGS AND VICE VERSA*/
    return { columns, filteredColumns, rows, columnNames, columnExtensions };
  }

  isNTMtoPBT() {
    let isNMT = this.state.transMethod.val === "nmt";
    let isSourceEN = this.state.sourceLang.lang === this.en;
    let isTargetEN = this.state.targetLang === this.en;
    return isNMT && !isSourceEN && !isTargetEN;
  }

  renderContent() {
    var content;
    /* IF FILE HAS BEEN UPLOADED */
    let overlay = "";
    if (this.state.file) {
      let {
        columns,
        filteredColumns,
        rows,
        columnNames,
        columnExtensions
      } = this.preparePreviewProps();

      let currentSheet = this.state.currentSheet;
      let selected = this.state.selectedCols[currentSheet];
      let langCols = this.state.sourceLang.langCols;
      let langCol = langCols[currentSheet];
      let isGlobal = this.state.sourceLang.isGlobal;

      /* IF FILE HAS BEEN SENT TO API */
      if (this.state.waiting) {
        overlay = <LoaderOverlay />;

        let response = this.state.response;
        if (response) {
          /* IF JOB SUCCESSFULLY QUEUED*/
          if (response.success) {
            let jobId = this.state.response.data.job_id;
            overlay = (
              <SuccessOverlay
                callback={this.finishJob}
                jobId={jobId}
                isNTMtoPBT={this.isNTMtoPBT()}
              />
            );
          } else {
            /* IF ERROR */
            let message = response.error.message;
            let code = response.error.code;
            overlay = (
              <ErrorOverlay
                code={code}
                message={message}
                callback={this.finishJob}
              />
            );
          }
        }
      }

      /* PLAIN PREVIEW AREA */

      let hasAllLangCols = true;
      let accumulated = 0;

      let sheets = this.state.sheetNames.map(s => {
        let count = this.state.selectedCols[s].length;
        if (!isGlobal && count > 0) {
          hasAllLangCols = hasAllLangCols && langCols[s];
        }
        accumulated += count;
        return { name: s, selected: count };
      });

      let allowSend =
        (isGlobal
          ? this.state.sourceLang.lang &&
            this.state.targetLang &&
            accumulated > 0
          : hasAllLangCols) && accumulated > 0;

      content = (
        <div className="p-0 m-0">
          {overlay}
          <Grid
            item
            className="m-4 border rounded"
            style={{ backgroundColor: "white" }}
          >
            <Paper className="border-bottom">
              <SettingsToolbar
                allowSend={allowSend}
                sheetNames={sheets}
                currentSheet={this.state.currentSheet}
                columns={filteredColumns}
                setSheet={this.setSheet}
                transMethod={this.state.transMethod}
                transMethods={this.state.transMethods}
                setTransMethod={this.setTransMethod}
                sourceLangs={this.state.sourceLangs}
                sourceLang={this.state.sourceLang.lang}
                langCol={langCol}
                setSourceLang={this.setSourceLang}
                isGlobal={this.state.sourceLang.isGlobal}
                setIsGlobal={this.setIsGlobal}
                targetLangs={this.state.targetLangs}
                targetLang={this.state.targetLang}
                setTargetLang={this.setTargetLang}
                sendFile={this.makeObject}
                cancel={this.finishJob}
              />
            </Paper>

            <Paper className="p-4">
              <Typography
                component="span"
                align="center"
                className="m-0 p-0"
                variant="subtitle2"
              >
                Click on one or more columns to select them for translation
              </Typography>

              <PreviewArea
                columns={columns}
                rows={rows}
                columnNames={columnNames}
                columnExtensions={columnExtensions}
                selectedCols={selected}
                langCol={langCol}
                selectCol={this.selectCol}
              />
            </Paper>
          </Grid>
        </div>
      );
    } else {
      /* INITIAL DROP FILE HERE SCREEN */
      if (this.state.wrongFileType) {
        overlay = (
          <ErrorOverlay
            code="99"
            message={`Expected .xlsx, got .${this.state.wrongFileType}`}
            callback={this.finishJob}
          />
        );
      }
      content = (
        <div className="d-flex flex-1">
          {overlay}
          <FileInput
            setFileInfo={this.setFileInfo}
            wrongFileType={this.wrongFileType}
          />
        </div>
      );
    }

    return content;
  }

  render() {
    return (
      <Grid container direction="column" className="flex-1">
        {this.renderContent()}
      </Grid>
    );
  }
}

export default ExcelTranslator;
