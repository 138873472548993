
import React, { Component } from 'react';
import Favicon from 'react-favicon';
import { Typography } from '@material-ui/core';
import logo_full from '../assets/img/logo_full.svg';
import favicon from '../assets/img/favicon.ico';

class LoginBox extends Component {

    render(){

      return (
        <div className="login-container">
          <Favicon url={favicon} />

          <div
            className="fw-login-card p-4"
          >

            <div id="login-logo">
              <img
                alt="fw-logo"
                id="fw-logo"
                src={logo_full}
                width="75%"
              />
            </div>

            <div
              className="mx-auto mt-2"
            >
              <Typography className="login-text">
                Please&nbsp;
                <button
                  className="clickable"
                  onClick={this.props.login}
                >
                  <span className="text-highlight">log in</span>
                </button>
                &nbsp;to continue.
              </Typography>
            </div>
            </div>
          </div>
      )
    }
 }

export default LoginBox;
