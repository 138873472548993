import React, { Component } from "react";
import XLSX from "xlsx";
import FileDrop from "react-file-drop";
import { Typography } from "@material-ui/core";
import MediaQuery from "react-responsive";

class FileInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.passClick = this.passClick.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.readFile = this.readFile.bind(this);
    this.handleOnLoadEnd = this.handleOnLoadEnd.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.startLoading = this.startLoading.bind(this);

    this.fileReader = new FileReader();
    this.fileReader.onloadend = this.handleOnLoadEnd;
    this.fileReader.onloadstart = this.startLoading;
  }

  startLoading() {
    this.setState({ loading: true });
  }

  handleOnLoadEnd() {
    const result = this.fileReader.result;
    const processed = XLSX.read(result, { type: "binary" });
    const sheets = processed.SheetNames;
    const sheetPreviews = sheets.map(sheetName => {
      let sheet = processed.Sheets[sheetName];
      let data = this.getPreview(sheet);
      return { sheetName: sheetName, preview: data };
    });

    this.props.setFileInfo(processed, sheetPreviews, this.state.file);
  }

  getPreview(sheet) {
    let rows = [];
    let range = XLSX.utils.decode_range(sheet["!ref"]);
    let [firstCol, lastCol, firstRow, lastRow] = [
      range.s.c,
      range.e.c,
      range.s.r,
      range.e.r
    ];

    if (lastRow > 10) {
      lastRow = 10;
    }
    var columns = [];

    for (var s = firstCol; s <= lastCol; s++) {
      let col = {};
      col["name"] = "col" + s;
      let cellRef = XLSX.utils.encode_cell({ r: 0, c: s });
      let title = sheet[cellRef];
      col["title"] = typeof title === "undefined" ? "Col" + s : title.v;
      columns.push(col);
    }

    for (var r = firstRow + 1; r <= lastRow; r++) {
      let rowData = {};
      for (var c = firstCol; c <= lastCol; c++) {
        let colName = "col" + c;
        let cellRef = XLSX.utils.encode_cell({ r: r, c: c });
        let cell = sheet[cellRef];
        let cellData;

        if (typeof cell === "undefined") {
          cellData = "";
        } else {
          cellData = cell.v;
        }
        rowData[colName] = cellData;
      }
      rows.push(rowData);
    }
    const preview = { columns: columns, rows: rows };
    return preview;
  }

  readFile(file) {
    let split = file.name.split(".");
    let type = split[split.length - 1];

    if (type === "xlsx") {
      this.setState(
        {
          file: file
        },
        () => {
          this.fileReader.readAsBinaryString(file);
        }
      );
    } else {
      this.props.wrongFileType(type);
    }
  }

  handleDrop(files) {
    if (files !== undefined && files.length !== 0) {
      this.readFile(files[0]);
    }
  }

  passClick() {
    this.refs.FileInput.click();
  }

  handleClick(event) {
    if (event.target.files !== undefined && event.target.files.length !== 0) {
      this.readFile(event.target.files[0]);
    }
  }

  render() {
    let info = this.state.loading ? (
      <>
        <img
          src="loading-1.svg"
          className="loader m-3"
          height={50}
          alt="Loading"
        />
        <br />
        <Typography component="span" variant="h2">
          Your file is being loaded
        </Typography>
        <Typography component="span" variant="subtitle1">
          This may take a while...
        </Typography>
      </>
    ) : (
      <>
        <Typography component="span" variant="h2" className="mb-3">
          Drop your XLSX file
        </Typography>
        <Typography component="span" variant="subtitle1">
          Or click to open file browser
        </Typography>
      </>
    );

    let desktopRender = (
      <div className="flex-1 d-flex" onClick={this.passClick}>
        <FileDrop
          className="d-flex align-items-center justify-content-center drop-zone m-5 flex-1 rounded"
          onDrop={this.handleDrop}
        >
          <div className="drop-zone-text flex-1">{info}</div>
          <input
            ref="FileInput"
            type="file"
            style={{ display: "none" }}
            onChange={this.handleClick}
          />
        </FileDrop>
      </div>
    );

    let mobileRender = (
      <div className="flex-1 d-flex w-100" onClick={this.passClick}>
        <FileDrop
          className="d-flex align-items-center justify-content-center drop-zone rounded w-100 m-2 h-75"
          onDrop={this.handleDrop}
        >
          <div className="drop-zone-text flex-1">{info}</div>
          <input
            ref="FileInput"
            type="file"
            style={{ display: "none" }}
            onChange={this.handleClick}
          />
        </FileDrop>
      </div>
    );

    return (
      <MediaQuery minWidth={500}>
        {matches => {
          if (matches) {
            return desktopRender;
          } else {
            return mobileRender;
          }
        }}
      </MediaQuery>
    );
  }
}

export default FileInput;
