import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import {
  Grid as TableGrid,
  Table,
  TableHeaderRow,
  TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';

import chevron from '../../assets/img/chevron.svg';


class Chevron extends Component {
  constructor(props) {
    super(props);
    let baseClass  = "chevron mx-auto";
    this.direction = props.direction
    this.className = this.direction === 'right' ? baseClass : baseClass+" chevron-left";
  }

  render() {
    const indicatorClass = "mt-auto mb-auto hidden-indicator hidden-indicator-"+(this.direction)
    const displayHidden  = (this.props.hidden === 0) ? '' : (
      <span className={indicatorClass}>
        <svg className="circle-indicator" xmlns="http://www.w3.org/2000/svg" version="1.1">
          <text fontWeight="bold" fill="#00abff" textAnchor="middle" x="0" y="20" >{this.props.hidden}</text>
          <circle cx="0" cy="15" r="15" stroke="#00abff" strokeWidth="2" fill="none" />
        </svg>
      </span>
    );

    return (
      <div className="d-flex ml-5 h-75" onClick={() => this.props.handleClick(this.direction)}>
        <div className="mt-auto mb-auto">
          <div className="outer-chevron">
            {displayHidden}
            <img src={chevron} alt="Pan right" className={this.className}/>
          </div>
        </div>

      </div>
    );
  }
}

class PreviewArea extends Component {

  constructor(props) {
    super(props);
    this.state = {
      step:1,
      displayStart: 0,
      displaySize: this.props.displaySize,
    }
    this.handleChevron = this.handleChevron.bind(this);
    this.handleSelect  = this.handleSelect.bind(this);
  }

  handleChevron(direction) {
    var vis    = this.state.displayStart;
    const size = this.state.displaySize
    const step = this.state.step;
    const amtCols = this.props.columnNames.length;

    switch(direction) {
      case 'right':
        if((vis+size) < amtCols) {
          vis += step;
        }
        break;
      case 'left':
        if(vis > 0) {
          vis -= step
        }
        break;
      default:
        console.log("what?")
        break;
    }
    this.setState({displayStart: vis});
  }

  handleSelect(selected) {
    const trueIndex     = this.props.columnNames.indexOf(selected);
    this.props.selectCol(trueIndex);
  }

  generatePreview(){
    let sheet        = this.props.previews.find(
      (s) => { return s.sheetName === this.props.currentSheet} );
    let columns      = sheet.preview.columns;
    let rows         = sheet.preview.rows;
    let columnNames  = columns.map( (el) => { return el.name} );
    let columnExtensions = columnNames.map(
      (el) => { return { name: el, wordWrapEnabled: true }
    });
    return {columns, rows, columnNames, columnExtensions}
  }

  render() {

    const currentSheet = this.props.currentSheet;
    const selectedCols  = this.props.selectedCols[currentSheet];

    const { columns, rows, columnNames, columnExtensions } = this.generatePreview();
    const { displayStart, displaySize } = this.state;

    const hiddenLeft    = selectedCols.filter( i => i < displayStart).length;
    const hiddenRight   = selectedCols.filter( i => i >= (displayStart+displaySize)).length;

    return (
      <Grid container
        direction="row"
        className="h-75"
      >
        <Grid item
          xs={1}
        >
          <Chevron direction="left"
            handleClick={this.handleChevron}
            hidden={hiddenLeft}
          />
        </Grid>
        <Grid item
          xs={10}
        >
            <ViewGrid
              rows={rows}
              columns={columns}
              columnExtensions={columnExtensions}
              columnNames={columnNames}
              start={displayStart}
              size={displaySize}
              selectedIndex={selectedCols}
              handleSelect={this.handleSelect}
            />
        </Grid>
        <Grid item
          xs={1}
        >
          <Chevron direction="right"
            handleClick={this.handleChevron}
            hidden={hiddenRight}
          />
        </Grid>
      </Grid>
    )
  }
}


class ViewGrid extends React.Component {

  constructor(props) {
    super(props);
    this.renderCell = this.renderCell.bind(this);
  }

  renderCell(props, selectedNames) {
    const column = props.column.name

    if (selectedNames.includes(column)) {
      return (
        <Table.Cell
          {...props}
          style={{backgroundColor: "#cceeff"}}
          onClick={() => this.props.handleSelect(column)}
        />
      )
    } else {
      return (
        <Table.Cell
          {...props}
          onClick={() => this.props.handleSelect(column)}
        />
      )
    }
  }

  render(){
    var  columnNames    = this.props.columnNames.slice();
    const selectedIndex = this.props.selectedIndex;
    const start         = this.props.start;
    const size          = this.props.size;

    const selectedNames = selectedIndex.map((index) => {
      return columnNames[index];
    });

    columnNames.splice(start, size);

    return(
      <TableGrid
        rows={this.props.rows}
        columns={this.props.columns}
        className="preview-area"
      >
        <Table
          columnExtensions={this.props.columnExtensions}
          cellComponent={(props) => this.renderCell(props, selectedNames)}
        />
        <TableHeaderRow />
        <TableColumnVisibility
          hiddenColumnNames={columnNames}
        />
      </TableGrid>
    )
  }
}

export default PreviewArea;
