import React from 'react';
import { Typography } from '@material-ui/core';

class SubTitle extends React.Component {

  /* Displays the page title */

  render(){

    var subtitle_class
    //var text_class

    if (this.props.type === "home"){
        subtitle_class = "home_subtitle";
    //    text_class = "welcome_text";
    } else {
        subtitle_class = "subtitle";
    //    text_class = "sig-intro-text";
    }

    return (
      <Typography className={ subtitle_class } component='p' variant="body1" >
        {this.props.text}
      </Typography>
    )
  }
}

export default SubTitle
