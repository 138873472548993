
import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';

/*const Link = ({path, text}) => {
    return (
        <a href={"https://www.factworks.com/" + path}
            target="_blank" rel="noopener norefferer">
            <Typography color="textSecondary">
              {text}
            </Typography>
          </a>
    )
}*/

/*class FullFooter extends Component {
  render() {
    let section1 = (
        <a href="https://www.factworks.com/" rel="noopener noreferrer" target="_blank" >
          <img src={logo_white} alt="Factworks" className="footer-logo-sizing"/>
        </a>
    );

    let section2 = (
        <ul>
          <li>
            <Link
              path="#branding-loyality"
              text="Branding & Loyalty"
            />
          </li>
          <li>
            <Link
              path="#segmentation"
              text="Segmentation"
            />
          </li>
          <li>
            <Link
              path="#product-pricing"
              text="Product Portfolio & Pricing"
            />
          </li>
          <li>
            <Link
              path="#predictive-analytics"
              text="Business & Predictive Analytics"
            />
          </li>
        </ul>
    );

    let section3 = (
        <ul>
          <li><Link path="career" text="Career" /></li>
          <li><Link path="imprint" text="Imprint" /></li>
          <li><Link path="privacy-policy" text="Privacy Policy" /></li>
          <li><Link path="contact" text="Contact" /></li>
        </ul>
    );

    let section4 = (
      <div className="float-right">
        <a
          href="https://www.facebook.com/factworks"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img src={fb_share} alt="Facebook" className="footer-share-sizing"/>
        </a>
        <a
          href="https://www.xing.com/companies/factworksgmbh"
          target="_blank"
          rel="noopener noreferrer"
        >
            <img src={xing_share} alt="Xing" className="footer-share-sizing"/>
        </a>
        <a
          href="https://de.linkedin.com/company/factworks-gmbh"
          rel="noopener noreferrer" target="_blank"
        >
          <img src={linkedin_share} alt="Linked In" className="footer-share-sizing"/>
        </a>
      </div>
    );

    return (
      <MediaQuery minWidth={767}>
        {(matches) => {
          if(matches) {
            return (
              <Grid item
                container
                xl={12}
                wrap="nowrap"
                className="full-footer"
              >
                <Grid item container
                  wrap="nowrap"
                  className="mx-auto inner"
                >
                  <Grid item
                    xl={1}
                    className="w-100 section-1"
                  >
                    { section1 }
                  </Grid>
                  <Grid item
                    xl={4}
                    className="w-100 section-2"
                  >
                    { section2 }
                  </Grid>
                  <Grid  item
                    xl={4}
                    className="w-100 section-3"
                  >
                    { section3 }
                  </Grid>
                  <Grid item
                    xl={3}
                  >
                    { section4 }
                  </Grid>
                </Grid>
              </Grid>
            );
          }
          else {
            return (
              <Grid item
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                direction="column"
                className="footer"
              >
                <Grid item
                  className="section-1"
                >
                  { section1 }
                </Grid>
                <Grid item
                  className="section-2"
                >
                  { section2 }
                </Grid>
                <Grid item
                  md={3}
                  className="section-3 darker"
                >
                  { section3 }
                </Grid>
                <Grid item
                  md={3}
                  className="section-4"
                >
                  { section4 }
                </Grid>
              </Grid>
            );
          }
        }}
      </MediaQuery>
    );
  }
}
*/
class Footer extends Component {
  render() {
    return (
      <Grid item container
        className="footer p-4"
      >
        <Typography component="span" align="center" color="textSecondary" style={{fontSize: '16px'}}>
          © <b>Factworks</b> 2020 - <a href="https://www.factworks.com/imprint">Imprint</a> - <a href="https://www.factworks.com/privacy-policy">Privacy Policy</a>
        </Typography>
      </Grid>
    );
  }
}


export default Footer;
