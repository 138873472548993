import React, { Component } from 'react';
import { Range }            from 'rc-slider';
import 'rc-slider/assets/index.css';
import { HuePicker } from 'react-color';
import { Menu, Dropdown, Button, Icon, InputNumber, Radio, Input } from 'antd'
import { linearHues } from './lib/defaults.js';
import { Typography } from '@material-ui/core'

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Settings extends Component {

  constructor(props) {

    super(props);

    this.state = {
      dropdownVisibleColor: false,
      dropdownVisibleFont: false,
      typing: false,
      typingTimeOut: 0,
      linearHue: this.props.settings.colorLinear, // keeps selected item on dropdown updated; -1 === black
      selectedScheme: 0, // keeps selected item on dropdown updated
      rotationRange: [-45, 45]
    }

    this.updateScale = this.updateScale.bind(this);
    this.updateLayout = this.updateLayout.bind(this);
    this.updateColorScheme = this.updateColorScheme.bind(this);
    this.updateFont = this.updateFont.bind(this);
    this.generateColors = this.generateColors.bind(this);
    this.generateFonts = this.generateFonts.bind(this);
    this.updateMaxWords = this.updateMaxWords.bind(this);
    this.updateColorPicker = this.updateColorPicker.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.updateRotationRange = this.updateRotationRange.bind(this);
    this.isLinear = this.isLinear.bind(this);

    this.colorLinearOptions = linearHues.map( (hue, index) => {
      return (<span
        key={hue}
        style={{backgroundColor: this.getHSL(hue)}}>&nbsp;</span>);
    });

    this.colorOptions = this.props.colorSchemes.map( (scheme) => {
      let title = scheme.label;
      let allColors = scheme.colors.map( (c, index) =>
      {
        return (
          <span
            key={index + 100}
            title={title}
            className="unmask"
            style={{backgroundColor: c}}>&nbsp;</span>);
        })

        return {colors: allColors, key: scheme.index}
      });
  }

  isLinear() {
    return (this.props.cloudType === 'linear');
  }

  updateRotationRange(newRange){
    this.setState({
      rotationRange: newRange,
    })
  }

  getHSL(hue) {
    hue = parseInt(hue)
    if (hue === -1){
      return 'hsl(0, 0%, 50%)';
    } else {
      return 'hsl('+hue+', 60%, 50%)';
    }
  }

  updateScale(ev){
    this.props.updateSetting('scale', ev.target.value);
  }

  updateLayout(ev){
    this.props.updateSetting('wordLayout', ev.target.value);
  }

  updateColorScheme(ev){
    // NB: This does not work properly due to an issue with the library
    let i = ev.key;
    let visible = false;

    if(this.isLinear()) {
      if (i !== 'color-picker') {
        this.props.updateSetting('colorLinear', linearHues[i]);
      } else {
        visible = true;
      }
    } else {
      this.props.updateSetting('colorScheme', this.props.colorSchemes.find(
        (scheme) => {
          return scheme.index === parseInt(i)
        }
      ));
    }

    this.setState({ dropdownVisibleColor: visible });
  }

  updateColorPicker(color) {
    this.setState( { linearHue: color.hsl.h}, () => this.props.updateSetting('colorLinear', color.hsl.h));
  }

  handleVisibleChange(flag, dropdown) {
    let key = 'dropdownVisible'+dropdown
    this.setState({ [key]: flag });
  }

  updateFont(ev, isEnter){
    let visible = false;

    if (isEnter !== true) { // if the event was called by Menu instead of Input
      if (ev.key === 'custom'){
        visible = true
      } else {
        this.props.updateSetting('font', ev.key);
      }
    } else { // event was called by enter press on Input
      this.props.updateSetting('font', ev.target.value);
    }
    this.setState({ dropdownVisibleFont: visible });
  }

  generateFonts(){
    var fontOptions = this.props.fonts.map( (font) => {
      return (
        <Menu.Item key={font} value={font} style={{fontFamily: font}} className="unmask">
          {font}
        </Menu.Item>
      )
    });

    let customFont = (
      <Menu.Item key="custom" style={{fontFamily: 'Factworks'}} className="unmask">
        <Input
          className="unmask"
          placeholder="System font (please provide full name)"
          onPressEnter={(ev) => this.updateFont(ev, true)}
        />
      </Menu.Item>
    );

    this.props.isLoggedIn() && fontOptions.push(customFont);

    return fontOptions;
  }

  generateColors(){
    let colorPicker, options;

    if (this.isLinear()) {
      options = this.colorLinearOptions.map( (o, index) => {
        return (
          <Menu.Item key={index} className="d-flex mx-2 unmask w-100">
            {o}
          </Menu.Item>
        )
      });

      colorPicker  = (
        <Menu.Item className="d-flex w-100 unmask" key="color-picker" >
          <HuePicker className="mx-auto w-100"
            onChangeComplete={this.updateColorPicker}
            color={() => this.getHSL(this.state.linearHue)}
          />
        </Menu.Item>)
        options.push(colorPicker)
    } else {
      options = this.colorOptions.map( (o) => {
        return (
          <Menu.Item key={o.key} className="d-flex mx-2 unmask w-100">
            {o.colors}
          </Menu.Item>
        )
      });
    }

    return options;
  }

  updateMaxWords(val){
    this.props.updateSetting('maxWords', Number(val));
  }

  componentWillReceiveProps(prevProps, prevState, snapshot){
    this.setState({
      rotationRange: prevProps.settings.rotationRange,
    })
  }

  render() {
    const fontOptions  = this.generateFonts();
    const colorOptions = this.generateColors();
    const fontMenu     = (
      <Menu onClick={this.updateFont}>
        {fontOptions}
      </Menu>
    );

    const colorMenu = (
      <Menu onClick={this.updateColorScheme} className="color-menu">
        {colorOptions}
      </Menu>
    );

    let displayColor;

    if(this.isLinear()) {
      displayColor = (<span style={{backgroundColor: this.getHSL(this.props.settings.colorLinear)}}>&nbsp;</span>)
    } else {
      let displayItem = colorOptions.find((c) => {
        let f = parseInt(c.key)
        let g = parseInt(this.props.settings.colorScheme.index)
        return f === g
      })
      displayColor = displayItem.props.children
    }

    const { settings, settings : { font } } = this.props

    return (
      <div className="m-3 mr-0 settings">
        <div className="row border-bottom mb-2 pb-2">
          <Typography component="span" variant="h5" align="center">
            Settings
          </Typography>
        </div>

        { /* ============== AMT WORDS ==============*/}
          <div className="row flex-row align-items-baseline pb-4 pt-3">
            <span className="col-5 max-words">Max. words:</span>
            <InputNumber className="form-control settings-max-amount"
              ref="maxWords"
              id="maxWords"
              value={settings.maxWords}
              onChange={this.updateMaxWords}
            />
          </div>

        { /* ============== MIN FREQ CUTOFF WORDS ==============*/}
          <div className="row flex-row align-items-baseline py-2 pb-4">
            <span className="min-freq-label col-5 mb-0">Min. frequency:</span>
            <InputNumber className="settings-min-freq form-control mb-0"
              ref="MinFreq" id="minFrequency"
              value={settings.minFrequency}
              onChange={(num) => this.props.updateSetting('minFrequency', num)}
            />
          </div>
        { /* ============== FONTS ==============*/}
          <div className="w-100 d-flex font-selector">
            <div className="dropdown-left-btn border rounded d-flex">
              <span className="mx-auto mt-1">Font</span>
            </div>
            <Dropdown className="w-100 d-flex"
              trigger={['click']}
              overlay={fontMenu}
              onVisibleChange={(flag) => this.handleVisibleChange(flag, 'Font')}
              visible={this.state.dropdownVisibleFont}
            >
              <Button className="w-100 d-flex dropdown-right-btn">
                <span style={{fontFamily: font}}>{font}</span>
                <span className="float-right auto-width">
                  <Icon type="down" />
                </span>
              </Button>
            </Dropdown>
          </div>
        { /* ============== COLORS ==============*/}
          <div className="w-100 d-flex mt-2">
            <div className=" border rounded dropdown-left-btn d-flex">
              <span className="mx-auto mt-1">Colors</span>
            </div>
            <Dropdown
              trigger='click'
              overlay={colorMenu}
              className="w-100 d-flex"
              onVisibleChange={(flag) => this.handleVisibleChange(flag, 'Color')}
              visible={this.state.dropdownVisibleColor}
            >
              <Button className="w-100 d-flex dropdown-right-btn">
                <span className="d-flex mr-3">{displayColor}</span>
                <span className="float-right auto-width">
                  <Icon type="down" />
                </span>
              </Button>
            </Dropdown>
          </div>
          { /* ============== ROTATION ==============*/}
            <div className="row pb-4 pt-4">
            <div className="rotation-range-label col pr-0">
              Rotation range:
            </div>
            <div className="col-8 rotation-range-slider">
              <Range
                value={this.state.rotationRange}
                ref="Rotation"
                min={-90}
                max={90}
                marks={
                    {
                    '-90': '-90',
                    '0': '',
                    90: '90'
                    }
                }
                onChange={(r) => this.updateRotationRange(r)}
                onAfterChange={(r) => this.props.updateSetting('rotationRange', r)}
		            disabled={this.isLinear()}
              />
            </div>
          </div>
          <br />
        { /* ============== ROTATION RANGE ==============*/}
           <div className="row pb-4">
            <div className="col pr-0">
                       </div>
            <div className="col-8">
              <Button
                type="default"
                size="small"
                className="flatten-button"
	              disabled={this.isLinear()}
                onClick={() => this.props.updateSetting('rotationRange', [0,0])}>
                No rotation
                </Button>
                <span id="settings-rotation-range-value" style={{whiteSpace: "nowrap"}}>
                {`[${settings.rotationRange[0]}, ${settings.rotationRange[1]}]`}
                </span>
            </div>
          </div>
          { /* ============== ROTATION POINTS ==============*/}
          <div className="row flex-row align-items-baseline pb-4">
            <span className="col-4 rotation-points-label">Rotation angles:</span>
            <InputNumber
	            className="form-control settings-max-amount"
              ref="RotationPoints"
              value={settings.rotationPoints}
              onChange={(num) => this.props.updateSetting('rotationPoints', num)}
              id="rotationPoints"
	            disabled={this.isLinear()}
            />
          </div>
          { /* ============== SCALE ==============*/}
          <div className="row pb-4">
            <div className="col-4">
              Scale:
            </div>
	    <div id="wc-scale-group">
	      <RadioGroup
		onChange={this.updateScale}
		defaultValue="sqrt"
		disabled={this.isLinear()}
    size="small"
		>
	        <RadioButton
			defaultChecked={this.props.settings.scale === "sqrt"}
			value="sqrt">Sqrt</RadioButton>
	        <RadioButton
			defaultChecked={settings.scale === "linear"}
			value="linear">Linear</RadioButton>
	      </RadioGroup>
	    </div>
          </div>
          { /* ============== WORD LAYOUT ==============*/}
          <div className="row pb-4">
          <div className="col-4">
            Layout:
          </div>
	        <div id="wc-scale-layout">
	          <RadioGroup
		          onChange={this.updateLayout}
		          defaultValue="rectangular"
		          disabled={this.isLinear()}
              size="small"
		        >
	            <RadioButton
                className="wc-rect-button"
		            value="rectangular"
		            defaultChecked={settings.layout === "rectangular"}>
			          Rectangular</RadioButton>
                {window.innerWidth <= 1450 && window.innerWidth >= 1300 ? <br /> : null}
	            <RadioButton
                className="wc-arch-button"
		            defaultChecked={settings.layout === "archimedean"}
		            value="archimedean">Archimedean</RadioButton>
	          </RadioGroup>
	        </div>
        </div>
      </div>
    );
  }
}

export default Settings;
