import React from 'react';
import { Typography } from '@material-ui/core';

class ResultsExplanation extends React.Component {
  /* Displays explanation of results for Proportion Tests */
  render() {

    return (
      <div id="prop_explanation">
        <Typography variant="h3" className="">
          How to interpret results?
        </Typography>
        <Typography variant="subtitle1" className="mt-3">
          z-Value
        </Typography>
        <Typography variant="subtitle2" className="">
          The z-Value is the ratio of the
          difference of both proportions and their standard deviation.
        </Typography>
        <Typography variant="subtitle1" className="mt-3">
          p-Value
        </Typography>
        <Typography variant="subtitle2" className="">
          The two observed proportions are significantly different if the p-value is smaller than (1-signifiance level).
        </Typography>
      </div>
    )
  }
}

export default ResultsExplanation
