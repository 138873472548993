export const CONFIG = {

    'API': {

        'base': 'https://api.myfactworks.com/',

     },

    'AUTH0': {

        'domain': 'factworks.auth0.com',

        'clientID': 'lybjdQWMZ6lpw3480kWH9wKFa2Tb7E6q',

        'redirectUri': 'https://tools.myfactworks.com/callback'

    },

    'GOOGLE_ANALYTICS': {

        'key': 'UA-127737556-2'

    },

    'MODE': 'production'

}
