import React from 'react';
import { Typography } from '@material-ui/core';

const Overlay = (props) => (
    <div className="overlay d-flex align-items-center justify-content-center">
      <Typography className="job-bg border rounded p-5 pb-3" align="center">
        {props.children}
      </Typography>
    </div>
)

const LoaderOverlay = () => (
    <Overlay className="overlay">
      <img
        src="loading-1.svg"
        className="loader"
        alt="Loading"/>
      <br/>
      <Typography component="span" variant="h6" className="m-2">Creating job...</Typography>
    </Overlay>
)

const SuccessOverlay = (props) => (
    <Overlay>
      <Typography variant="h6" component="span">
        Your file was successfully queued for translation! <br/>
        A confirmation e-mail will be sent to you soon <br/>
        Your job ID is <span className="job-id">{props.jobId}</span>
      </Typography>
        {props.isNTMtoPBT && <Typography variant="body2">Translation method changed to Phrase-based due to language restrictions.<br/></Typography>}
        <button className="btn btn-primary float-right mt-2" type="button"
          onClick={props.callback}>
          Ok
        </button>
    </Overlay>
)

const ErrorOverlay = (props) => (
    <Overlay>
     <Typography variant="h6" component="span">
      <b>An error has ocurred:</b> <br/>
      Error {props.code}: {props.message}<br/>
      <button className="btn btn-primary float-right mt-2" type="button"
        onClick={props.callback}
      >
          Ok
        </button>
      </Typography>
    </Overlay>
)

export { LoaderOverlay, SuccessOverlay, ErrorOverlay };
